import { Footer } from '../Footer/Footer';
import { makeStyles } from '../../Themes/MakeStyles';
import { getBranding } from '../../Themes/Branding';
import React from 'react';
import { RouteUrls } from '../../Routes/RouteUrls';
import { useRecoilValue } from 'recoil';
import { WorkstationLandingLoading } from '../../Recoil/Atoms';
import Header from '../Header/Header';
import { useLocation } from 'react-router-dom';
import { CustomSnackbar } from '../Common/CustomSnackbar';

export interface IAppLayoutProps {
    children: React.ReactNode;
}

// This was built with help from the following source https://dev.to/ramonak/dashboard-layout-with-react-js-and-material-ui-27m4

const UserAppLayout: React.FC<IAppLayoutProps> = (props) => {
    const { classes, cx } = useStyles();
    const workstationLandingLoading = useRecoilValue(WorkstationLandingLoading);
    const location = useLocation();

    return (
        <div
            className={cx(
                classes.Application,
                location.pathname === RouteUrls.WorkRemoteLanding && !workstationLandingLoading
                    ? classes.WorkStationLandingBackground
                    : classes.DefaultBackground
            )}
        >
            <CustomSnackbar />
            {location.pathname !== RouteUrls.AdvancedConfiguration && <Header />}
            <div className={cx(classes.ContentContainer)}>{props.children}</div>
            <div className={cx(classes.FooterContainer)}>
                <Footer></Footer>
            </div>
        </div>
    );
};

export default UserAppLayout;

const useStyles = makeStyles()((theme) => ({
    Application: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    WorkStationLandingBackground: getBranding().WorkstationLandingBackground,
    DefaultBackground: getBranding().DefaultBackground,
    FooterContainer: {
        bottom: '0.875rem',
        width: '100vw',
        height: '2rem',
        marginBottom: '.5rem',
        marginTop: '1rem',
    },
    ContentContainer: {
        width: '100vw',
        flex: 1,
    },
}));
