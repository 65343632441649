import { VegaStickleyTheme } from './VegaStickleyTheme/VegaStickleyTheme';
import { ReactComponent as SoSLogo } from '../Images/sos-logo.svg';
import { ReactComponent as QumuloLogo } from '../Images/qumulo-logo.svg';
import { ReactComponent as VegaLogo } from '../Images/vega-logo.svg';
import { ReactComponent as VegaLogoWhite } from '../Images/vega-logo-white.svg';
import { VegaQumuloTheme } from './VegaQumoloTheme/VegaQumuloTheme';
import { VegaTheme } from './VegaTheme/VegaTheme';
import { VegaAtelierTheme } from './VegaAtelierTheme/VegaAtelierTheme';
import WorkstationLandingBackground from '../Images/cloud-workstation.svg';
import AtelierWorkstationLandingBackground from '../Images/atlier-workstation-landing-bg.png';
import AtelierDefaultBackground from '../Images/atelier-default-bg.png';
import { Theme } from '@mui/material';
import { CSSObject } from 'tss-react';
import { CSSProperties } from 'react';

const classes: IClasses = {
    Icon: {
        width: 'auto',
        height: '2rem',
    },
    AdminSidebarIcon: {
        width: 'auto',
        height: '4rem',
    },
    WorkStationLandingBackground: {
        background: `url(${WorkstationLandingBackground})`,
        height: '25%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top right',
        backgroundSize: 'auto',
    },
    AtelierWorkstationLandingBackground: {
        background: `url(${AtelierWorkstationLandingBackground})`,
        height: '100%',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
    },
    AtelierDefaultBackground: {
        background: `url(${AtelierDefaultBackground})`,
        height: '100%',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
    },
    EmptyBackground: {},
};

const Branding: BrandingRecord = {
    Stickley: {
        Theme: VegaStickleyTheme,
        Icon: <SoSLogo style={{ ...classes.Icon }} />,
        AdminSidebarIcon: <SoSLogo style={{ ...classes.AdminSidebarIcon }} />,
        Brand: 'Stickley on Security',
        WorkstationLandingBackground: classes.WorkStationLandingBackground,
        DefaultBackground: classes.EmptyBackground,
        DefaultTextColor: VegaStickleyTheme.palette.common.black,
        AccentTextColor: VegaStickleyTheme.palette.common.black,
    },
    Qumulo: {
        Theme: VegaQumuloTheme,
        Icon: <QumuloLogo style={{ ...classes.Icon }} />,
        AdminSidebarIcon: <QumuloLogo style={{ ...classes.AdminSidebarIcon }} />,
        Brand: 'Qumulo',
        WorkstationLandingBackground: classes.WorkStationLandingBackground,
        DefaultBackground: classes.EmptyBackground,
        DefaultTextColor: VegaStickleyTheme.palette.common.black,
        AccentTextColor: VegaStickleyTheme.palette.text.secondary,
    },
    Vega: {
        Theme: VegaTheme,
        Icon: <VegaLogo style={{ ...classes.Icon }} />,
        AdminSidebarIcon: <VegaLogo style={{ ...classes.AdminSidebarIcon }} />,
        Brand: 'Vega',
        WorkstationLandingBackground: classes.WorkStationLandingBackground,
        DefaultBackground: classes.EmptyBackground,
        DefaultTextColor: VegaStickleyTheme.palette.common.black,
        AccentTextColor: VegaStickleyTheme.palette.text.secondary,
    },
    Atelier: {
        Theme: VegaAtelierTheme,
        Icon: <VegaLogoWhite style={{ ...classes.Icon }} />,
        AdminSidebarIcon: <VegaLogoWhite style={{ ...classes.AdminSidebarIcon }} />,
        Brand: 'atelier',
        WorkstationLandingBackground: classes.AtelierWorkstationLandingBackground,
        DefaultBackground: classes.AtelierDefaultBackground,
        DefaultTextColor: VegaStickleyTheme.palette.common.white,
        AccentTextColor: VegaStickleyTheme.palette.common.white,
    },
};

export const getBranding = (): IBranding => {
    return Branding[process.env.REACT_APP_BRANDING];
};

export enum BRANDS {
    Stickley = 'Stickley',
    Qumulo = 'Qumulo',
    Vega = 'Vega',
    Atelier = 'Atelier',
}

interface IClasses {
    [key: string]: CSSProperties & CSSObject;
}

type BrandingRecord = {
    [key in BRANDS]: IBranding;
};

interface IBranding {
    Theme: Theme;
    Icon: JSX.Element;
    AdminSidebarIcon: JSX.Element;
    Brand: string;
    WorkstationLandingBackground: CSSObject;
    DefaultBackground: CSSObject;
    DefaultTextColor: string;
    AccentTextColor: string;
}
