import { CommonThemeOverrides } from '../CommonThemeOverrides';
import { VegaAtilierPalette } from './VegaAtilierPalette';
import { VegaAtelierTypography } from './VegaAtelierTypography';
import { createTheme } from '@mui/material';

export const VegaAtelierTheme = createTheme({
    components: CommonThemeOverrides,
    palette: VegaAtilierPalette,
    typography: VegaAtelierTypography,
    spacing: 8,
});
