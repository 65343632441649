import React, { useEffect, useState } from 'react';
import { makeStyles } from '../../../Themes/MakeStyles';
import { AdminWorkstationsTable } from './AdminWorkstationsTable/AdminWorkstationsTable';
import { useRecoilValue } from 'recoil';
import { Authentication, ListWorkstations } from '../../../Recoil/Atoms';

export interface IAdminWorkstationsProps {
    isLoading: boolean;
}

const AdminWorkstationsController: React.FC<IAdminWorkstationsProps> = (props) => {
    const styles = useStyles(props);
    const authorized = useRecoilValue(Authentication);
    const workstations = useRecoilValue(ListWorkstations(authorized ? authorized.userId : ''));

    return (
        <>
            <AdminWorkstationsTable isLoading={props.isLoading} workstations={[...workstations]} />
        </>
    );
};

const useStyles = makeStyles<IAdminWorkstationsProps>()((theme, props) => ({}));

export { AdminWorkstationsController };
