import { Components } from '@mui/material';

export const CommonThemeOverrides = {
    MuiCard: {
        defaultProps: {
            elevation: 0,
        },
        styleOverrides: {
            root: {
                borderRadius: 8,
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: 'none',
                borderRadius: 32,
            },
        },
        defaultProps: {
            variant: 'outlined',
            disableElevation: true,
        },
    },
    MuiButtonBase: {
        defaultProps: {
            disableRipple: true,
            disableTouchRipple: true,
        },
    },
    MuiGrid: {
        defaultProps: {
            spacing: 4,
        },
    },
    MuiFormControl: {
        defaultProps: {
            variant: 'outlined',
        },
    },
    MuiTextField: {
        defaultProps: {
            variant: 'outlined',
        },
    },
    MuiTableCell: {
        head: {
            color: 'grey',
        },
    },
    MuiTableHead: {
        styleOverrides: {
            root: {
                '& .MuiTableCell-head': {
                    color: 'black !important',
                    fontSize: '1rem',
                },
            },
        },
    },
} as Components;
