import React, { useRef, useState } from 'react';
import { makeStyles } from '../../../../Themes/MakeStyles';
import { Button, Card, CardContent, Grid, Paper, Stack, Typography, useTheme } from '@mui/material';
import { cx } from 'tss-react/@emotion/css';
import { AddExistingWorkstationDialog } from './AddExistingWorkstationDialog';
import { Workstation } from '../../../../ServiceStack/ServiceStack.dtos';
import { AdminWorkstationsActionsMenu } from '../AdminWorkstationsActionsMenu';
import { getCloudProviderIcon, uuidv4 } from '../../../../Utility/Utility';
import { useLocation, useNavigate } from 'react-router-dom';
import { getWorkstationStatus } from '../../AdminCommon/Helpers';
import MaterialTable, { MTableToolbar, MTableHeader } from 'material-table';
import { tableIcons } from '../../../Common/TableIcons';
import { RouteUrls } from '../../../../Routes/RouteUrls';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { Authentication, ListWorkstationsId } from '../../../../Recoil/Atoms';

export interface IAdminWorkstationsTableProps {
    workstations: Workstation[];
    isLoading: boolean;
}

const AdminWorkstationsTable: React.FC<IAdminWorkstationsTableProps> = (props) => {
    const styles = useStyles(props);
    const theme = useTheme();
    const [showAddExistingWorkstationDialog, setShowAddExistingWorkstationDialog] = useState(false);
    const navigate = useNavigate();
    const [selectedWorkstations, setSelectedWorkstations] = useState<Workstation[]>([]);
    const tableRef = useRef();
    const location = useLocation();
    const authorized = useRecoilValue(Authentication);
    const setListWorkstationId = useSetRecoilState(ListWorkstationsId(authorized ? authorized.userId : ''));

    const onClearSelections = () => {
        if (tableRef.current) {
            (tableRef as any).current.onAllSelected(false);
        }
        setSelectedWorkstations([]);
    };

    const onCloseAddExistingWorkstationsDialog = (setListWorkstationId?: SetterOrUpdater<string>) => {
        setShowAddExistingWorkstationDialog(false);
        setListWorkstationId && setListWorkstationId(uuidv4);
    };

    return (
        <>
            <AddExistingWorkstationDialog open={showAddExistingWorkstationDialog} onClose={onCloseAddExistingWorkstationsDialog} />
            <Card elevation={0}>
                <CardContent>
                    <MaterialTable
                        key={uuidv4()}
                        tableRef={tableRef}
                        icons={tableIcons}
                        components={{
                            Container: (props) => <Paper {...props} elevation={0} />,
                            Toolbar: (props) => (
                                <Grid container direction={'row'} justifyContent={'space-between'}>
                                    <Grid item xs={7}>
                                        <MTableToolbar {...props} />
                                    </Grid>
                                    <Grid item className={cx(styles.classes.TableToolbarButtons)}>
                                        <Button
                                            onClick={() => setShowAddExistingWorkstationDialog(true)}
                                            color={'primary'}
                                            variant={'contained'}
                                            className={cx(styles.classes.AddWorkstationButton)}
                                        >
                                            + ADD EXISTING WORKSTATION
                                        </Button>
                                        <Button
                                            onClick={() => setListWorkstationId(uuidv4())}
                                            color={'primary'}
                                            variant={'outlined'}
                                            className={cx(styles.classes.AddWorkstationButton)}
                                        >
                                            Refresh Status
                                        </Button>
                                        <AdminWorkstationsActionsMenu
                                            disabled={selectedWorkstations.length < 1}
                                            showMapUsers={false}
                                            workstations={selectedWorkstations}
                                            onClearSelections={onClearSelections}
                                        />
                                    </Grid>
                                </Grid>
                            ),
                            Header: (props) => <MTableHeader {...props} elevation={0} />,
                        }}
                        columns={[
                            {
                                title: 'Workstation Name',
                                field: 'name',
                                render: (rowData) => {
                                    return (
                                        <>
                                            <Button
                                                onClick={() => navigate(`${rowData.machineId}/overview`)}
                                                className={cx(styles.classes.TableButtons)}
                                                variant={'text'}
                                            >
                                                {rowData.name}
                                            </Button>
                                            <Typography>{rowData.instanceId}</Typography>
                                        </>
                                    );
                                },
                            },
                            {
                                title: 'Users',
                                field: 'users',
                                render: (rowData) => {
                                    return (
                                        <Stack justifyContent={'flex-start'} alignItems={'flex-start'} direction={'column'}>
                                            {rowData.users.map((user) => (
                                                <Button
                                                    onClick={() =>
                                                        navigate(`${RouteUrls.AdminUsers}/${user.userGuid}`, {
                                                            state: { from: { pathname: location.pathname } },
                                                        })
                                                    }
                                                    key={user.userGuid}
                                                    className={cx(styles.classes.TableButtons)}
                                                    variant={'text'}
                                                >
                                                    {user.userName}
                                                </Button>
                                            ))}
                                        </Stack>
                                    );
                                },
                            },
                            {
                                title: 'Provider',
                                field: 'cloudProvider',
                                render: (rowData) => {
                                    return getCloudProviderIcon(rowData.cloudProvider, styles.classes.CloudProviderIcon);
                                },
                            },
                            {
                                title: 'Size/SKU',
                                field: 'size',
                                render: (rowData) => {
                                    return <Typography>{rowData.size}</Typography>;
                                },
                            },
                            {
                                title: 'Status',
                                field: 'status',
                                render: (rowData) => {
                                    return getWorkstationStatus(rowData.status, true);
                                },
                            },
                            {
                                title: 'Actions',
                                field: 'type',
                                render: (rowData) => {
                                    return <AdminWorkstationsActionsMenu disabled={false} showMapUsers={true} workstations={[rowData]} />;
                                },
                            },
                        ]}
                        data={props.workstations.map((w) => w)}
                        localization={{
                            toolbar: {
                                searchPlaceholder: 'Search...',
                            },
                        }}
                        options={{
                            showTitle: false,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 30],
                            searchFieldAlignment: 'left',
                            searchFieldVariant: 'outlined',
                            searchFieldStyle: {
                                marginLeft: '-1.5rem',
                                marginTop: '1rem',
                                marginBottom: '1rem',
                                width: '160%',
                            },
                            headerStyle: {
                                backgroundColor: `${theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]}`,
                                color: theme.palette.grey[500],
                            },
                            selection: true,
                            showTextRowsSelected: false,
                        }}
                        onSelectionChange={setSelectedWorkstations}
                        isLoading={props.isLoading}
                    />
                </CardContent>
            </Card>
        </>
    );
};

const useStyles = makeStyles<IAdminWorkstationsTableProps>()((theme, props) => ({
    AddWorkstationButton: {
        marginRight: '1rem',
    },
    CloudProviderIcon: {
        height: '2rem',
    },
    TableButtons: {
        display: 'inline-block',
        padding: 0,
        minHeight: 0,
        minWidth: 0,
    },
    TableToolbarButtons: {
        marginTop: '1.25rem',
    },
}));

export { AdminWorkstationsTable };
