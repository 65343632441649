import { ReactComponent as AWSIcon } from '../Images/aws-logo.svg';
import { cx } from 'tss-react/@emotion/css';
import { ReactComponent as AzureIcon } from '../Images/microsoft.svg';
import { ReactComponent as GoogleIcon } from '../Images/google-logo.svg';
import React from 'react';

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getCloudProviderIcon = (cloudProvider: string, className: string) => {
    switch (cloudProvider) {
        case 'aws':
            return <AWSIcon className={cx(className)} />;
        case 'azure':
            return <AzureIcon className={cx(className)} />;
        case 'gcp':
            return <GoogleIcon className={cx(className)} />;
        default:
            return cloudProvider;
    }
};
