import React, { useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { LocationState } from '../../Types/Types';
import { Box, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { getBranding } from '../../Themes/Branding';
import { makeStyles } from '../../Themes/MakeStyles';
import PasswordInput from '../Common/PasswordInput';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { RouteUrls } from '../../Routes/RouteUrls';

interface ISignupProps {}

const Signup: React.FC<ISignupProps> = (props) => {
    const [error, setError] = useState<string>(undefined);
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const { classes, cx } = useStyles();
    const navigate = useNavigate();

    const onChangePassword = (password) => {
        setPassword(password);
    };

    const onClickLoginLink = () => {
        navigate(RouteUrls.Login);
    };

    const onClickSignUp = () => {
        //ToDo set error from response if any
        setError(undefined);
    };

    return (
        <Box display='flex' height={'calc(100vh - 13rem)'} width={'100vw'} alignItems={'center'} justifyContent={'center'}>
            <Box
                display='flex'
                alignItems={'center'}
                justifyContent={'center'}
                height={'40rem'}
                width={'30rem'}
                bgcolor={getBranding().Theme.palette.common.white}
            >
                <Grid container direction='column' justifyContent='center' alignItems='center'>
                    <Grid item xs={12}>
                        <div>
                            <Typography variant={'h5'} className={cx(classes.TextInline)}>
                                Sign Up for Vega
                            </Typography>
                            <Typography
                                variant={'h5'}
                                color={getBranding().Theme.palette.primary.main}
                                className={cx(classes.TextInline, classes.BrandText)}
                            >
                                {getBranding().Brand}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className={cx(classes.InputField)}
                            variant='outlined'
                            fullWidth
                            id='name'
                            label='Full Name'
                            name='name'
                            autoComplete='name'
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className={cx(classes.InputField)}
                            variant='outlined'
                            fullWidth
                            id='email'
                            label='Email Address'
                            name='email'
                            autoComplete='email'
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordInput
                            textFieldClassname={cx(classes.InputField)}
                            fullWidth={true}
                            error={error !== undefined}
                            errorMessage={error}
                            id={'passwordInput'}
                            name={'password'}
                            placeholder='Password'
                            setPwd={onChangePassword}
                            value={password}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PhoneInput
                            placeholder={'Phone Number'}
                            inputClass={cx(classes.InputField, classes.PhoneInput)}
                            country={'us'}
                            value={phone}
                            onChange={(phone) => {
                                setPhone(phone);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={onClickSignUp} className={cx(classes.InputField)} color={'primary'} variant={'contained'}>
                            Sign Up
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            {'Already have an account?'}
                            <Link className={cx(classes.LoginLink)} onClick={onClickLoginLink}>
                                Login
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles()((theme) => ({
    TextInline: {
        display: 'inline-block',
    },
    BrandText: {
        marginLeft: '.5rem',
    },
    InputField: {
        minWidth: '25rem',
    },
    PhoneInput: {
        minHeight: '3.5rem',
        '&.form-control:focus': {
            borderColor: getBranding().Theme.palette.primary.main,
            boxShadow: `0 0 0 1px ${getBranding().Theme.palette.primary.main}`,
        },
    },
    LoginLink: {
        padding: 0,
        color: theme.palette.common.black,
        textDecorationColor: theme.palette.common.black,
        marginLeft: '.35rem',
    },
}));

export default Signup;
