import * as ReactDOMClient from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import React from 'react';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { Routes } from './Routes/Routes';
import './global.css';
import { getBranding } from './Themes/Branding';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

export const muiCache = createCache({
    key: 'mui', // all material ui classes start with 'css' instead of 'mui' even with this here
    prepend: true,
});

root.render(
    <React.StrictMode>
        <CacheProvider value={muiCache}>
        <BrowserRouter>
            <RecoilRoot>
                <ThemeProvider theme={getBranding().Theme}>
                    <Routes />
                </ThemeProvider>
            </RecoilRoot>
        </BrowserRouter>
        </CacheProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
