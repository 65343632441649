import { VegaStickleyPalette } from './VegaStickleyPalette';
import { VegaStickleyTypography } from './VegaStickleyTypography';
import { CommonThemeOverrides } from '../CommonThemeOverrides';
import { createTheme } from '@mui/material';

export const VegaStickleyTheme = createTheme({
    components: CommonThemeOverrides,
    palette: VegaStickleyPalette,
    typography: VegaStickleyTypography,
    spacing: 8,
});
