import React, { useRef, useState } from 'react';
import { makeStyles } from '../../../../../Themes/MakeStyles';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import { AddUserEntitlements, User, Workstation } from '../../../../../ServiceStack/ServiceStack.dtos';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Authentication, ListUsers, ListUsersId, ListWorkstationsId, ServiceStackClient, SnackBarOptions } from '../../../../../Recoil/Atoms';
import { SearchRounded } from '@mui/icons-material';
import { AdminWorkstationDetailsUserManagementDropdown } from './AdminWorkstationDetailsUserManagementDropdown';
import { AdminWorkstationDetailsUserManagementTable } from './AdminWorkstationDetailsUserManagementTable';
import { uuidv4 } from '../../../../../Utility/Utility';

export interface IAdminWorkstationDetailsUserManagementProps {
    workstation: Workstation;
    users: User[];
}

const AdminWorkstationDetailsUserManagement: React.FC<IAdminWorkstationDetailsUserManagementProps> = (props) => {
    const styles = useStyles(props);
    const [snackBarOptions, setSnackbarOptions] = useRecoilState(SnackBarOptions);
    const authorized = useRecoilValue(Authentication);
    const users = useRecoilValue(ListUsers(authorized ? authorized.userId : ''));
    const [userGuids, setUserGuids] = React.useState<string[]>([]);
    const client = useRecoilValue(ServiceStackClient);
    const setListWorkstationId = useSetRecoilState(ListWorkstationsId(authorized ? authorized.userId : ''));
    const setListUsersId = useSetRecoilState(ListUsersId(authorized ? authorized.userId : ''));
    const [refreshDropdown, setRefreshDropdown] = useState(false);

    const onClickAddUsers = () => {
        client
            .post(new AddUserEntitlements({ machineId: props.workstation.machineId, userGuidList: userGuids }))
            .then((response) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message:
                        userGuids.length > 1
                            ? `${userGuids.length} users mapped to ${props.workstation.name}`
                            : userGuids.length === 1 &&
                              `${users.find((user) => user.userGuid === userGuids[0]).fullName} has been mapped to ${props.workstation.name}`,
                });
                setUserGuids([]);
                setRefreshDropdown(true);
                setListWorkstationId(uuidv4());
                setListUsersId(uuidv4());
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `There was an error adding users`,
                });
            });
    };

    const usersNotAlreadyOnWorkstation =
        users &&
        users.filter((user) => {
            if (props.workstation.users.find((u) => u.userGuid === user.userGuid)) {
            } else {
                return user;
            }
        });

    return (
        <Grid container direction={'column'}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container direction={'column'} spacing={2}>
                            <Grid container item xs={12}>
                                <Grid item>
                                    <Typography variant={'h6'}>Manage User Mapping for Workstation</Typography>
                                </Grid>
                            </Grid>
                            <Grid spacing={2} direction={'column'} container item xs={12}>
                                <Grid item xs={12}>
                                    <Typography variant={'body2'}>Select user to map to workstation</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction='row' spacing={1} alignItems='center'>
                                        <AdminWorkstationDetailsUserManagementDropdown
                                            refreshMenu={refreshDropdown}
                                            onChangeUserGuids={setUserGuids}
                                            users={usersNotAlreadyOnWorkstation !== undefined ? usersNotAlreadyOnWorkstation : []}
                                        />
                                        <Button disabled={userGuids.length < 1} onClick={onClickAddUsers} variant={'contained'}>
                                            Add
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <AdminWorkstationDetailsUserManagementTable
                                        workstation={props.workstation}
                                        users={users.filter((user) =>
                                            props.workstation.users.some((workstationUser) => workstationUser.userGuid === user.userGuid)
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles<IAdminWorkstationDetailsUserManagementProps>()((theme, props) => ({}));

export { AdminWorkstationDetailsUserManagement };
