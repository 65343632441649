import React, { useState } from 'react';
import { Divider, Drawer, Grid, Typography } from '@mui/material';
import { SupportForm } from '../SupportForm/SupportForm';
import { makeStyles } from '../../Themes/MakeStyles';
import { AccountCircle } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { Authentication } from '../../Recoil/Atoms';

export interface IProfileDrawerProps {
    show: boolean;
    onClose: () => void;
    user: User;
}

export class User {
    userName: string;
    email: string;
    company: string;

    public constructor(init?: Partial<User>) {
        (Object as any).assign(this, init);
    }
}

enum ViewMode {
    Profile,
    ChangePassword,
}

const ProfileDrawer: React.FC<IProfileDrawerProps> = (props) => {
    const { classes, cx } = useStyles();
    const [viewMode, setViewMode] = useState(ViewMode.Profile);
    const authenticationResponse = useRecoilValue(Authentication);

    const onCancelClick = () => setViewMode(ViewMode.Profile);

    const profileView = (
        <Grid
            container
            direction={
                authenticationResponse &&
                authenticationResponse.roles &&
                authenticationResponse.roles.filter((role) => role === 'SecureWorkRemoteAdmin').length > 0
                    ? 'row'
                    : 'column'
            }
            className={cx(classes.ContentContainer)}
        >
            <Grid justifyContent={'center'} container item xs={12}>
                <AccountCircle className={cx(classes.AccountIcon)} />
            </Grid>
            <Grid justifyContent={'center'} container item xs={12}>
                <Typography variant={'subtitle1'}>{`${authenticationResponse && authenticationResponse.displayName}'s Profile`}</Typography>
            </Grid>
            <Grid justifyContent={'center'} container item xs={12}>
                <Typography variant={'subtitle1'}>{`Company: ${props.user.company}`}</Typography>
            </Grid>
            <Grid justifyContent={'center'} container item xs={12}>
                <Typography variant={'subtitle1'}>{`${props.user.email}`}</Typography>
            </Grid>
            <Grid className={classes.Divider} item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    );

    return (
        <Drawer className={cx(classes.DrawerContainer)} anchor={'right'} open={props.show} onClose={props.onClose}>
            <Grid spacing={4} container direction={'column'} className={cx(classes.ContentContainer)}>
                {viewMode === ViewMode.Profile && profileView}
                {viewMode === ViewMode.ChangePassword && (
                    <>
                        {profileView}
                        <SupportForm show={viewMode === ViewMode.ChangePassword} onClose={onCancelClick} />
                    </>
                )}
                {/*
                {viewMode == ViewMode.ChangePassword && <ChangePassword onCancelClick={onCancelClick} />}
*/}
            </Grid>
        </Drawer>
    );
};

const useStyles = makeStyles()((theme) => ({
    DrawerContainer: {
        width: '100%',
        overflowX: 'hidden',
    },
    ContentContainer: {
        paddingTop: '.5rem',
        margin: 0,
        width: '20rem',
        overflowY: 'hidden',
    },
    AccountIcon: {
        height: '5rem',
        width: '5rem',
        fill: theme.palette.grey.A700,
    },
    Divider: {
        paddingRight: 0,
        paddingLeft: '0 !important',
    },
}));

export { ProfileDrawer };
