import React, { useState } from 'react';
import { makeStyles } from '../../Themes/MakeStyles';
import { getBranding } from '../../Themes/Branding';
import { WorkstationSizeSelector } from './WorkstationSizeSelector';
import { WorkstationConfigurationFinish } from './WorkstationConfigurationFinish';
import { Grid, IconButton, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface IAdvancedConfigurationProps {}

const AdvancedConfigurationController: React.FC<IAdvancedConfigurationProps> = (props) => {
    const { classes, cx } = useStyles();
    const [workstationSizeSelected, setWorkstationSizeSelected] = useState(false);
    const [selectedWorkstationSize, setSelectedWorkstationSize] = useState<string>();
    const [operatingSystem, setOperatingSystem] = React.useState<string | null>('windows');
    const navigate = useNavigate();

    const onChangeOperatingSystem = (event: React.MouseEvent<HTMLElement>, os: string | null) => {
        setOperatingSystem(os);
    };

    const onChangeSelectedWorkstationSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedWorkstationSize(event.target.value);
    };

    const onClickBackToWorkstation = () => {
        navigate(-1);
    };

    const onClickConfirmSelection = () => {
        setWorkstationSizeSelected(!workstationSizeSelected);
    };

    return (
        <Grid container spacing={2} alignItems='center' justifyContent='center' direction='column'>
            <Grid item>
                <IconButton className={cx(classes.BackButton)} onClick={onClickBackToWorkstation}>
                    <ArrowBack className={cx(classes.ArrowBack)}></ArrowBack>
                    <Typography color={getBranding().DefaultTextColor}>Back to Workstation Page</Typography>
                </IconButton>
                {!workstationSizeSelected ? (
                    <WorkstationSizeSelector
                        operatingSystem={operatingSystem}
                        onChangeOperatingSystem={onChangeOperatingSystem}
                        selectedWorkstationSize={selectedWorkstationSize}
                        onChangeSelectedWorkstationSize={onChangeSelectedWorkstationSize}
                        onClickConfirmSelection={onClickConfirmSelection}
                    />
                ) : (
                    <WorkstationConfigurationFinish />
                )}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles()((theme) => ({
    ContentContainer: {
        height: 'calc(100vh - 0.875rem)',
    },
    BackButton: {
        marginLeft: '-5rem',
    },
    ArrowBack: {
        marginRight: '1rem',
        fill: theme.palette.common.white,
    },
}));

export default AdvancedConfigurationController;
