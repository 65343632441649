import { Route, Routes as RRRoutes, Navigate } from 'react-router-dom';
import { RouteUrls } from './RouteUrls';
import React, { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilSnapshot, useRecoilState, useRecoilValue } from 'recoil';
import { Authentication, ListUsers, ListWorkstations, ListWorkstationsId, ServiceStackClient } from '../Recoil/Atoms';
import { NormalUserRoutes } from './NormalUserRoutes';
import { AdminUserRoutes } from './AdminUserRoutes';
import Logout from '../Components/Logout/Logout';
import Login from '../Components/Login/Login';
import { Authenticate, ResponseStatus } from '../ServiceStack/ServiceStack.dtos';
import UserAppLayout from '../Components/AppLayout/UserAppLayout';
import { JsonServiceClient } from '@servicestack/client';

export interface IRoutesProps {}

export const Routes: React.FC<IRoutesProps> = (props) => {
    const [authenticationResponse, setAuthenticationResponse] = useRecoilState(Authentication);
    const [checkingAuthStatus, setCheckingAuthStatus] = useState(true);
    const snapshot = useRecoilSnapshot();

    const preFetch = useRecoilCallback(({ snapshot, set }) => async (userID: string) => {
        //set(ListWorkstationsId(''), userID);
        snapshot.getLoadable(ListWorkstations(userID));
        snapshot.getLoadable(ListUsers(userID));
    });

    //Prefetch doesnt work without this
    useEffect(() => {
        // @ts-ignore
        for (const node of snapshot.getNodes_UNSTABLE()) {
            snapshot.getLoadable(node);
        }
    }, [snapshot]);

    useEffect(() => {
        if (authenticationResponse === undefined) {
            checkAuthStatus().then((r) => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkAuthStatus = async () => {
        const client = new JsonServiceClient(process.env.REACT_APP_BASE_URL);
        client
            .post(new Authenticate({ rememberMe: true }))
            .then((response) => {
                client.useTokenCookie = true;
                setAuthenticationResponse(response);
                setCheckingAuthStatus(false);
                response.roles && response.roles.filter((role) => role === 'SecureWorkRemoteAdmin').length > 0 && preFetch(response.userId);
            })
            .catch((error) => {
                console.log(error);
                setCheckingAuthStatus(false);
            });
    };

    return (
        <RRRoutes>
            <Route path={`${RouteUrls.Login}#s=1`} element={<Navigate to={RouteUrls.Login} />} />
            <Route path={RouteUrls.Logout} element={<Logout />} />
            <Route
                path={RouteUrls.Login}
                element={
                    <UserAppLayout>
                        <Login />
                    </UserAppLayout>
                }
            />
            <Route path={'/'} element={<Navigate to={RouteUrls.Login} />} />
            <Route
                path='*'
                element={
                    authenticationResponse &&
                    authenticationResponse.roles &&
                    authenticationResponse.roles.filter((role) => role === 'SecureWorkRemoteAdmin').length > 0 ? (
                        <AdminUserRoutes />
                    ) : !checkingAuthStatus &&
                      (authenticationResponse === undefined ||
                          (authenticationResponse &&
                              authenticationResponse.responseStatus &&
                              authenticationResponse.responseStatus.message === 'Unauthorized')) ? (
                        <Navigate to={RouteUrls.Login} />
                    ) : (
                        <NormalUserRoutes />
                    )
                }
            />
        </RRRoutes>
    );
};
