import { Navigate, useNavigate } from 'react-router-dom';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { JsonServiceClient } from '@servicestack/client';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Authentication, ServiceStackClient } from '../../Recoil/Atoms';
import { Authenticate } from '../../ServiceStack/ServiceStack.dtos';
import { RouteUrls } from '../../Routes/RouteUrls';

export default function Logout() {
    const [loading, setLoading] = useState(true);
    const [client, setClient] = useRecoilState(ServiceStackClient);
    const setAuthenticationResponse = useSetRecoilState(Authentication);
    const navigate = useNavigate();

    useEffect(() => {
        client
            .post(new Authenticate({ provider: 'logout' }))
            .then((response) => {
                setClient(new JsonServiceClient(process.env.REACT_APP_BASE_URL));
                setAuthenticationResponse(undefined);

                setLoading(false);
                /* window.location.assign(
                    `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}/oauth2/logout?post_logout_redirect_uri=${process.env.REACT_APP_VANITY_URL}/logout`
                );*/
            })
            .catch((error) => {
                console.log(error);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // redirect to login after all clean
    return loading ? (
        <Grid container direction='column' justifyContent='center' alignItems='center'>
            <Grid item xs={12}>
                <CircularProgress />
            </Grid>
            <Grid item xs={12}>
                <Typography>Logging Out</Typography>
            </Grid>
        </Grid>
    ) : (
        <Navigate to={RouteUrls.Login} />
    );
}
