import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RouteUrls } from '../../Routes/RouteUrls';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SupportForm } from '../SupportForm/SupportForm';
import { Authentication, ServiceStackClient, WorkstationDetailsAtom } from '../../Recoil/Atoms';
import { Authenticate } from '../../ServiceStack/ServiceStack.dtos';
import { makeStyles } from '../../Themes/MakeStyles';
import { getBranding } from '../../Themes/Branding';
import { LocationState } from '../../Types/Types';
import { JsonServiceClient } from '@servicestack/client';

export interface ILoginProps {}

const Login: React.FC<ILoginProps> = (props) => {
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const { classes, cx } = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const [authenticationResponse, setAuthenticationResponse] = useRecoilState(Authentication);

    const onClickLogin = () => {
        window.location.assign(`${process.env.REACT_APP_BASE_URL}auth/microsoftgraph`);
    };

    useEffect(() => {
        //handle directing to previous page before needing new authorization
        const { from } = location.state !== null && (location.state as LocationState);
        if (authenticationResponse && location.state && from) {
            navigate(from.pathname ? from.pathname : from);
        } else if (authenticationResponse && authenticationResponse) {
            navigate(RouteUrls.WorkRemoteLanding);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticationResponse && authenticationResponse.userId]);

    //ToDo is there a better way to check auth status? MPG
    useEffect(() => {
        checkAuthStatus().then((r) => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkAuthStatus = async () => {
        const client = new JsonServiceClient(process.env.REACT_APP_BASE_URL);
        client
            .post(new Authenticate())
            .then((response) => {
                client.useTokenCookie = true;
                setAuthenticationResponse(response);
            })
            .catch((error) => console.log(error));
    };

    return showForgotPassword ? (
        /*
        <ForgotPasswordController onCancel={handleShowForgotPassword}></ForgotPasswordController>
*/
        <SupportForm show={showForgotPassword} onClose={() => setShowForgotPassword(false)} />
    ) : (
        <Grid direction={'column'} alignItems={'center'} container>
            <Grid item xs={12}>
                <Typography color={getBranding().DefaultTextColor} variant={'h3'}>
                    WorkRemote Workstation
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color={getBranding().AccentTextColor} className={cx(classes.ContentTypography)} variant={'body2'}>
                    Sign in with active directory to access your workstation
                </Typography>
            </Grid>
            <Grid container direction={'column'} alignItems={'center'}>
                <Grid item xs={4}>
                    <Button onClick={onClickLogin} type='submit' variant='contained' color='primary'>
                        Sign In
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles()((theme) => ({
    ContentTypography: {
        marginBottom: '1rem',
    },
}));

export default Login;
