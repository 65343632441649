import React from 'react';
import { makeStyles } from '../../../Themes/MakeStyles';
import { AdminUsersTable } from './AdminUsersTable/AdminUsersTable';
import { useRecoilValue } from 'recoil';
import { Authentication, ListUsers, ListWorkstations } from '../../../Recoil/Atoms';

export interface IAdminUsersProps {
    isLoading: boolean;
}

const AdminUsersController: React.FC<IAdminUsersProps> = (props) => {
    const styles = useStyles(props);
    const authorized = useRecoilValue(Authentication);
    const users = useRecoilValue(ListUsers(authorized ? authorized.userId : ''));

    return <AdminUsersTable users={users} isLoading={props.isLoading} />;
};

const useStyles = makeStyles<IAdminUsersProps>()((theme, props) => ({}));

export { AdminUsersController };
