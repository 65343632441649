import React from 'react';
import { makeStyles } from '../../../Themes/MakeStyles';
import { Stack, Typography } from '@mui/material';
import { cx } from 'tss-react/@emotion/css';

export interface ITwoRowTextProps {
    topText: JSX.Element | string;
    bottomText: JSX.Element | string;
    bottomTextClassName?: string;
}

const TwoRowText: React.FC<ITwoRowTextProps> = (props) => {
    const styles = useStyles(props);

    return (
        <Stack direction='column' spacing={1} alignItems='initial'>
            <Typography variant={'body1'} component={'span'}>
                {props.topText}
            </Typography>
            <Typography color={'#9AA8C1'} variant={'body1'} component={'span'} className={cx(props.bottomTextClassName)}>
                {props.bottomText}
            </Typography>
        </Stack>
    );
};

const useStyles = makeStyles<ITwoRowTextProps>()((theme, props) => ({}));

export { TwoRowText };
