import React, { PropsWithChildren, Suspense } from 'react';
import { makeStyles } from '../../Themes/MakeStyles';
import { AdminSidebar } from '../Admin/AdminSidebar/AdminSidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdminHeader } from '../Admin/AdminHeader/AdminHeader';
import { Box, CircularProgress, Container, CssBaseline, Grid, Toolbar, Typography } from '@mui/material';
import { CustomSnackbar } from '../Common/CustomSnackbar';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorScreen } from '../Common/ErrorScreen';
import { RouteUrls } from '../../Routes/RouteUrls';
import { useRecoilState } from 'recoil';
import { SnackBarOptions } from '../../Recoil/Atoms';

export interface IAdminAppLayoutProps extends PropsWithChildren {}

//ToDo add sidebar
const AdminAppLayout: React.FC<IAdminAppLayoutProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [resetError, setResetError] = React.useState(false);
    const location = useLocation();

    const onError = (error: Error, info: { componentStack: string }) => {
        //ToDo can log errors to external service
    };

    return (
        <Box className={cx(classes.App)}>
            <CssBaseline />
            <CustomSnackbar />
            <AdminHeader />
            <AdminSidebar />
            <Box component='main' className={cx(classes.Main)}>
                <Toolbar />
                <Container sx={{ mt: 4, mb: 4 }} maxWidth={false}>
                    <Suspense
                        fallback={
                            <Grid container direction={'column'} alignItems={'center'}>
                                <Grid item xs={12}>
                                    <CircularProgress />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Loading...</Typography>
                                </Grid>
                            </Grid>
                        }
                    >
                        <ErrorBoundary
                            FallbackComponent={ErrorScreen}
                            onReset={(props) => {
                                setResetError(false);
                            }}
                            onError={onError}
                            resetKeys={[resetError, location.pathname]}
                        >
                            {props.children}
                        </ErrorBoundary>
                    </Suspense>
                </Container>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles<IAdminAppLayoutProps>()((theme, props) => ({
    App: {
        display: 'flex',
    },
    Main: {
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
}));

export { AdminAppLayout };
