export const RouteUrls = {
    Logout: '/logout',
    Login: '/login',
    Signup: '/signup',
    WorkRemoteLanding: '/workremote/home',
    AdvancedConfiguration: '/workremote/configuration',
    AdminHealth: '/admin/health',
    AdminWorkstations: '/admin/workstations',
    AdminUsers: '/admin/users',
};
