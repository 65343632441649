import React, { useState } from 'react';
import { makeStyles } from '../../../Themes/MakeStyles';
import { Divider, Drawer as MuiDrawer, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled, Typography } from '@mui/material';
import { cx } from 'tss-react/@emotion/css';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HealingOutlinedIcon from '@mui/icons-material/HealingOutlined';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ReactComponent as PoweredByVega } from '../../../Images/powered-by-vega.svg';
import { BRANDS, getBranding } from '../../../Themes/Branding';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteUrls } from '../../../Routes/RouteUrls';
import { SupportForm } from '../../SupportForm/SupportForm';

export interface IAdminSidebarProps {}

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: '16rem',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const AdminSidebar: React.FC<IAdminSidebarProps> = (props) => {
    const styles = useStyles(props);
    let navigate = useNavigate();
    const { pathname } = useLocation();
    const [showSupportForm, setShowSupportForm] = useState(false);

    return (
        <Drawer className={cx(styles.classes.Drawer)} elevation={0} variant='permanent' open>
            <SupportForm show={showSupportForm} onClose={() => setShowSupportForm(false)} />
            <Grid container className={cx(styles.classes.ContentContainer)}>
                <PoweredByVega className={cx(styles.classes.PoweredByVega)} />{' '}
            </Grid>
            <List component={'nav'} className={cx(styles.classes.Navigation)}>
                <ListItem key={'Home'} disablePadding>
                    <ListItemButton selected={pathname.includes(RouteUrls.WorkRemoteLanding)} onClick={() => navigate(RouteUrls.WorkRemoteLanding)}>
                        <ListItemIcon>
                            <HomeOutlinedIcon className={cx(styles.classes.NavIcon)} />
                        </ListItemIcon>
                        <ListItemText primary={'Home'} />
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem key={'Health'} disablePadding>
                    <ListItemButton selected={pathname.includes(RouteUrls.AdminHealth)} onClick={() => navigate(RouteUrls.AdminHealth)}>
                        <ListItemIcon>
                            <HealingOutlinedIcon className={cx(styles.classes.NavIcon)} />
                        </ListItemIcon>
                        <ListItemText primary={'Health'} />
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem key={'Workstations'} disablePadding>
                    <ListItemButton selected={pathname.includes(RouteUrls.AdminWorkstations)} onClick={() => navigate(RouteUrls.AdminWorkstations)}>
                        <ListItemIcon>
                            <DesktopWindowsOutlinedIcon className={cx(styles.classes.NavIcon)} />
                        </ListItemIcon>
                        <ListItemText primary={'Workstations'} />
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem key={'Users'} disablePadding>
                    <ListItemButton selected={pathname.includes(RouteUrls.AdminUsers)} onClick={() => navigate(RouteUrls.AdminUsers)}>
                        <ListItemIcon>
                            <GroupsIcon className={cx(styles.classes.NavIcon)} />
                        </ListItemIcon>
                        <ListItemText primary={'Users'} />
                    </ListItemButton>
                </ListItem>
            </List>
            <List className={cx(styles.classes.Help)}>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => setShowSupportForm(true)}>
                        <ListItemIcon>
                            <HelpOutlineIcon className={cx(styles.classes.NavIcon)} />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
    );
};

const useStyles = makeStyles<IAdminSidebarProps>()((theme, props) => ({
    Drawer: {
        '& .MuiDrawer-paper': { borderWidth: 0 },
    },
    Navigation: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    ContentContainer: {
        paddingTop: '.5rem',
        margin: 0,
        width: '16rem',
    },
    AccountIcon: {
        height: '5rem',
        width: '5rem',
        fill: theme.palette.grey.A700,
    },
    Divider: {
        paddingRight: 0,
        paddingLeft: '0 !important',
    },
    Logo: {
        height: '2rem',
        width: '2rem',
    },
    Help: {
        marginTop: `auto`,
    },
    PoweredByVega: {
        height: '4.5rem',
        marginTop: '2rem',
    },
    NavIcon: {
        fill: '#273754',
    },
}));

export { AdminSidebar };
