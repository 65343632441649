import React from 'react';
import { makeStyles } from '../../../Themes/MakeStyles';
import { useRecoilValue } from 'recoil';
import { Authentication, ListWorkstations } from '../../../Recoil/Atoms';
import { AdminHealth } from './AdminHealth';

export interface IAdminHealthControllerProps {}

const AdminHealthController: React.FC<IAdminHealthControllerProps> = (props) => {
    const styles = useStyles(props);
    const authorized = useRecoilValue(Authentication);
    const workstations = useRecoilValue(ListWorkstations(authorized ? authorized.userId : ''));

    return <AdminHealth isLoading={false} workstations={workstations} />;
};

const useStyles = makeStyles<IAdminHealthControllerProps>()((theme, props) => ({}));

export { AdminHealthController };
