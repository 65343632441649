import { Box, Typography } from '@mui/material';
import { getBranding } from '../../Themes/Branding';

interface IWorkstationConfigurationFinishProps {}

const WorkstationConfigurationFinish: React.FC<IWorkstationConfigurationFinishProps> = (props) => {
    return (
        <Box display='flex' height={'calc(100vh - 10rem)'} width={'calc(100vw - 12rem)'} alignItems={'center'} justifyContent={'center'}>
            <Typography align={'center'} width={'30rem'} variant={'h5'} color={getBranding().DefaultTextColor}>
                Setting up your work environment, this might take a moment. You will recieve an email when your workstation is finished.
            </Typography>
        </Box>
    );
};

export { WorkstationConfigurationFinish };
