import React from 'react';
import { Card, CardContent, CardHeader, FormControlLabel, Grid, Radio, Typography } from '@mui/material';
import { makeStyles } from '../../Themes/MakeStyles';

interface IWorkstationSizeCardProps {
    workstationSizeChoice: IWorkstationSizeChoice;
    selectedWorkstationSize: string;
    onChangeSelectedWorkstationSize: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface IWorkstationSizeChoice {
    size: string;
    price: string;
    vcpus: string;
    ram: string;
    gpu: string;
    memory: string;
}

const WorkstationSizeCard: React.FC<IWorkstationSizeCardProps> = (props) => {
    const { classes, cx } = useStyles();

    return (
        <Card className={cx(classes.Card)} elevation={0}>
            <CardHeader
                avatar={
                    <FormControlLabel
                        control={
                            <Radio
                                onChange={props.onChangeSelectedWorkstationSize}
                                value={props.workstationSizeChoice.size}
                                checked={props.workstationSizeChoice.size === props.selectedWorkstationSize}
                            />
                        }
                        label={props.workstationSizeChoice.size}
                    />
                }
                title={`$${props.workstationSizeChoice.price}`}
            />
            <CardContent>
                <Grid classes={{ root: classes.Grid }} container>
                    <Grid item xs={12}>
                        <Typography>{`- ${props.workstationSizeChoice.vcpus}`}</Typography>
                        <Typography>{`- ${props.workstationSizeChoice.ram}`}</Typography>
                        <Typography>{`- ${props.workstationSizeChoice.gpu}`}</Typography>
                        <Typography>{`- ${props.workstationSizeChoice.memory}`}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const useStyles = makeStyles()((theme) => ({
    Card: {
        border: 'solid .025rem',
        maxWidth: '18rem',
    },
    Grid: {
        marginTop: '-4rem',
        marginLeft: 0,
    },
}));

export { WorkstationSizeCard };
