import React from 'react';
import { Badge, BadgeProps, IconButton, Typography } from '@mui/material';
import { makeStyles } from '../../Themes/MakeStyles';
import { styled } from '@mui/styles';
import { cx } from 'tss-react/@emotion/css';

export interface IDotWithTextProps {
    text: string;
    color: string;
    isBlackText: boolean;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        padding: '0 4px',
    },
}));

const DotWithText: React.FC<IDotWithTextProps> = (props) => {
    const styles = useStyles(props);

    return (
        <IconButton className={cx(styles.classes.Button)} aria-label='cart'>
            <Badge className={cx(styles.classes.Badge)} variant='dot'>
                <Typography color={props.isBlackText ? 'black' : 'inherit'}>{props.text}</Typography>
            </Badge>
        </IconButton>
    );
};

const useStyles = makeStyles<IDotWithTextProps>()((theme, props) => ({
    Badge: {
        '& .MuiBadge-badge': {
            right: 70,
            top: 13,
            backgroundColor: props.color,
            padding: 0,
        },
        '& .MuiButtonBase-root-MuiIconButton-root': {
            padding: 0,
        },
        '& .MuiButtonBase-root-MuiIconButton-root:hover': {
            backgroundColor: 'transparent',
        },
    },
    Button: {
        marginLeft: '1rem',
        display: 'inline-block',
        padding: 0,
        minHeight: 0,
        minWidth: 0,
    },
}));

export { DotWithText };
