import React, { useEffect } from 'react';
import {
    Box,
    Checkbox,
    Chip,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    useTheme,
} from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import { makeStyles } from '../../../../../Themes/MakeStyles';
import { User } from '../../../../../ServiceStack/ServiceStack.dtos';
import { cx } from 'tss-react/@emotion/css';
import { LocationState } from '../../../../../Types/Types';
import { RouteUrls } from '../../../../../Routes/RouteUrls';

export interface IAdminWorkstationDetailsUserManagementDropdownProps {
    users: User[];
    onChangeUserGuids: (userGuids: string[]) => void;
    refreshMenu: boolean;
}

const AdminWorkstationDetailsUserManagementDropdown: React.FC<IAdminWorkstationDetailsUserManagementDropdownProps> = (props) => {
    const styles = useStyles(props);
    const theme = useTheme();
    const [userGuids, setUserGuids] = React.useState<string[]>([]);

    useEffect(() => {
        if (props.refreshMenu) {
            setUserGuids([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.users]);

    const onChangeUserGuids = (event: SelectChangeEvent<typeof userGuids>) => {
        event.preventDefault();
        const {
            target: { value },
        } = event;
        var dataToSet = typeof value === 'string' ? value.split(',') : value;
        setUserGuids(
            // On autofill we get a stringified value.
            dataToSet
        );
        props.onChangeUserGuids(dataToSet);
    };

    const onChipMouseDown = (event) => {
        event.stopPropagation();
    };

    const onDeleteChip = (userGuid: string) => {
        var filteredArray = userGuids.filter((s) => s !== userGuid);
        setUserGuids(filteredArray);
        props.onChangeUserGuids(filteredArray);
    };

    return (
        <FormControl fullWidth>
            <InputLabel>Search...</InputLabel>
            <Select
                IconComponent={(props) => <SearchRounded {...props} />}
                multiple
                classes={{
                    iconOpen: cx(styles.classes.SelectIconOpen),
                }}
                fullWidth={true}
                value={userGuids}
                onChange={onChangeUserGuids}
                input={<OutlinedInput label='Search...' />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip
                                onMouseDown={onChipMouseDown}
                                onDelete={() => onDeleteChip(value)}
                                key={value}
                                label={
                                    props.users.find((user) => user.userGuid === value) &&
                                    props.users.find((user) => user.userGuid === value).userName
                                }
                            />
                        ))}
                    </Box>
                )}
                MenuProps={{
                    PaperProps: {
                        className: cx(styles.classes.MenuPaper),
                    },
                }}
            >
                {props.users.map((user) => (
                    <MenuItem key={user.userGuid} value={user.userGuid}>
                        <Checkbox checked={userGuids.indexOf(user.userGuid) > -1} />
                        <ListItemText primary={`${user.userName} ${user.fullName}`} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const useStyles = makeStyles<IAdminWorkstationDetailsUserManagementDropdownProps>()((theme, props) => ({
    SelectIconOpen: {
        transform: 'rotate(0deg)',
    },
    MenuPaper: {
        maxHeight: 48 * 4.5 + 8,
        width: 'auto',
    },
}));

export { AdminWorkstationDetailsUserManagementDropdown };
