import React from 'react';
import { makeStyles } from '../../../../Themes/MakeStyles';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { TwoRowText } from '../../AdminCommon/TwoRowText';
import { User } from '../../../../ServiceStack/ServiceStack.dtos';
import { cx } from 'tss-react/@emotion/css';
import { useNavigate } from 'react-router-dom';
import { RouteUrls } from '../../../../Routes/RouteUrls';
import { format } from 'date-fns';

export interface IAdminUserDetailsProps {
    user: User;
}

const AdminUserDetails: React.FC<IAdminUserDetailsProps> = (props) => {
    const styles = useStyles(props);
    const navigate = useNavigate();

    return (
        <Grid container direction={'row'}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container direction={'column'} spacing={2}>
                            <Grid container item xs={12}>
                                <Grid item>
                                    <Typography variant={'h6'}>User Information</Typography>
                                </Grid>
                            </Grid>
                            <Grid justifyContent={'space-between'} container item xs={12}>
                                <Grid item xs={3}>
                                    <TwoRowText topText={'Full Name'} bottomText={props.user.fullName} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TwoRowText topText={'Username'} bottomText={props.user.userName} />
                                </Grid>
                                {/*Cant get via Teradici api currently     <Grid item xs={3}>
                                    <TwoRowText topText={'Email Address'} bottomText={props.user.emailAddress} />
                                </Grid>*/}
                                <Grid item xs={3}>
                                    <TwoRowText topText={'Created On'} bottomText={format(Date.parse(props.user.createdOn), 'MM/dd/yyyy')} />
                                </Grid>
                            </Grid>
                            <Grid justifyContent={'space-between'} container item xs={12}>
                                <Grid item xs={12}>
                                    <TwoRowText topText={'Deployment ID'} bottomText={props.user.deploymentId} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container direction={'column'} spacing={2}>
                            <Grid container item xs={12}>
                                <Grid item>
                                    <Typography variant={'h6'}>Assigned Workstations</Typography>
                                </Grid>
                            </Grid>
                            {props.user.workstations.map((workstation) => (
                                <Grid key={workstation.workstationId} justifyContent={'space-between'} container item xs={12}>
                                    <Grid item xs={3}>
                                        <TwoRowText
                                            topText={'Workstation Name'}
                                            bottomText={
                                                <Button
                                                    onClick={() => navigate(`${RouteUrls.AdminWorkstations}/${workstation.workstationId}/overview`)}
                                                    className={cx(styles.classes.WorkstationNameButton)}
                                                    variant={'text'}
                                                >
                                                    {workstation.workstationName}
                                                </Button>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TwoRowText topText={'Assigned On'} bottomText={format(Date.parse(workstation.assignedOn), 'MM/dd/yyyy')} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TwoRowText topText={'Provider'} bottomText={workstation.provider} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TwoRowText topText={'Size'} bottomText={workstation.size} />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles<IAdminUserDetailsProps>()((theme, props) => ({
    WorkstationNameButton: {
        display: 'inline-block',
        padding: 0,
        minHeight: 0,
        minWidth: 0,
    },
}));

export { AdminUserDetails };
