import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { makeStyles } from '../../../../Themes/MakeStyles';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { cx } from 'tss-react/@emotion/css';
import { SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Authentication, ListWorkstationsId, ServiceStackClient, SnackBarOptions } from '../../../../Recoil/Atoms';
import { AddExistingWorkstations, ListADComputers, TeradiciADComputer } from '../../../../ServiceStack/ServiceStack.dtos';
import { uuidv4 } from '../../../../Utility/Utility';

export interface IAddExistingWorkstationDialogProps {
    open: boolean;
    onClose: (setListWorkstationId?: SetterOrUpdater<string>) => void;
}

const AddExistingWorkstationDialog: React.FC<IAddExistingWorkstationDialogProps> = (props) => {
    const styles = useStyles(props);
    const [snackBarOptions, setSnackbarOptions] = useRecoilState(SnackBarOptions);
    const [adComputers, setADComputers] = useState<TeradiciADComputer[]>([]);
    const [selectedComputers, setSelectedComputers] = React.useState<string[]>([]);
    const client = useRecoilValue(ServiceStackClient);
    const authorized = useRecoilValue(Authentication);
    const setListWorkstationId = useSetRecoilState(ListWorkstationsId(authorized ? authorized.userId : ''));

    useEffect(() => {
        if (props.open) {
            client
                .get(new ListADComputers({ filterAdded: true }))
                .then((response) => {
                    setADComputers(response.results);
                })
                .catch((error) => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'error' },
                        message: `There was an error loading remote workstations`,
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    const onCloseDialog = (setListWorkstationId?: SetterOrUpdater<string>) => {
        setSelectedComputers([]);
        props.onClose(setListWorkstationId);
    };

    const onClickSave = () => {
        const workstationsToAdd = adComputers.filter((el) => {
            return selectedComputers.indexOf(el.computerName) !== -1;
        });

        client
            .post(new AddExistingWorkstations({ teradiciAdComputers: workstationsToAdd }))
            .then((response) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message:
                        selectedComputers.length > 1
                            ? `${selectedComputers.length} have been added`
                            : selectedComputers.length === 1 && `${selectedComputers[0]} has been added`,
                });
                //need to refresh our workstations
                onCloseDialog(setListWorkstationId);
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `There was an error adding remote workstations`,
                });
            });
    };

    const onChangeSelectedComputers = (event: SelectChangeEvent<typeof selectedComputers>) => {
        const {
            target: { value },
        } = event;
        setSelectedComputers(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    return (
        <Dialog key={props.open.toString()} maxWidth={'sm'} fullWidth open={props.open} onClose={onCloseDialog}>
            <DialogTitle>Add an existing remote workstation</DialogTitle>
            <DialogContent className={cx(styles.classes.DialogContent)}>
                <DialogContentText>Select a workstation to add from your Active Directory</DialogContentText>
                <FormControl className={cx(styles.classes.WorkstationSelect)} fullWidth>
                    <InputLabel id='demo-simple-select-label'>Select Workstation</InputLabel>
                    <Select
                        multiple
                        renderValue={(selected) => selected.join(', ')}
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={selectedComputers}
                        label='Select Workstation'
                        onChange={onChangeSelectedComputers}
                    >
                        {adComputers.map((adComputer) => (
                            <MenuItem key={adComputer.computerHostname} value={adComputer.computerName}>
                                <Checkbox checked={selectedComputers.indexOf(adComputer.computerName) > -1} />
                                <ListItemText primary={adComputer.computerName} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions className={cx(styles.classes.DialogContent, styles.classes.Buttons)}>
                <Button color={'inherit'} variant={'contained'} onClick={() => onCloseDialog()}>
                    Cancel
                </Button>
                <Button disabled={selectedComputers.length < 1} variant={'contained'} onClick={onClickSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles<IAddExistingWorkstationDialogProps>()((theme, props) => ({
    WorkstationSelect: {
        marginTop: '1rem',
    },
    DialogContent: {
        marginBottom: '.5rem',
    },
    Buttons: {
        marginRight: '1.25rem',
    },
}));

export { AddExistingWorkstationDialog };
