import React from 'react';
import { makeStyles } from '../../../../Themes/MakeStyles';
import { Button, Card, CardContent, Paper, Stack, useTheme } from '@mui/material';
import { User } from '../../../../ServiceStack/ServiceStack.dtos';
import { cx } from 'tss-react/@emotion/css';
import MaterialTable from 'material-table';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteUrls } from '../../../../Routes/RouteUrls';
import { tableIcons } from '../../../Common/TableIcons';
import { uuidv4 } from '../../../../Utility/Utility';

export interface IAdminUsersTableProps {
    users: User[];
    isLoading: boolean;
}

const AdminUsersTable: React.FC<IAdminUsersTableProps> = (props) => {
    const styles = useStyles(props);
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Card elevation={0}>
            <CardContent>
                <MaterialTable
                    key={uuidv4()}
                    icons={tableIcons}
                    components={{
                        Container: (props) => <Paper {...props} elevation={0} />,
                    }}
                    columns={[
                        {
                            title: 'Full Name',
                            field: 'fullName',
                            render: (rowData) => {
                                return (
                                    <Button onClick={() => navigate(rowData.userGuid)} className={cx(styles.classes.TableButtons)} variant={'text'}>
                                        {rowData.fullName}
                                    </Button>
                                );
                            },
                        },
                        {
                            title: 'Username',
                            field: 'userName',
                        },
                        {
                            title: 'Workstation',
                            field: 'workstations',
                            render: (rowData) => {
                                return (
                                    <Stack justifyContent={'flex-start'} alignItems={'flex-start'} direction={'column'}>
                                        {rowData.workstations.map((workstation) => (
                                            <Button
                                                key={workstation.workstationId}
                                                onClick={() =>
                                                    navigate(`${RouteUrls.AdminWorkstations}/${workstation.workstationId}/overview`, {
                                                        state: { from: { pathname: location.pathname } },
                                                    })
                                                }
                                                className={cx(styles.classes.TableButtons)}
                                                variant={'text'}
                                            >
                                                {workstation.workstationName}
                                            </Button>
                                        ))}
                                    </Stack>
                                );
                            },
                        },
                    ]}
                    data={props.users}
                    localization={{
                        toolbar: {
                            searchPlaceholder: 'Search...',
                        },
                    }}
                    options={{
                        showTitle: false,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30],
                        searchFieldAlignment: 'left',
                        searchFieldVariant: 'outlined',
                        searchFieldStyle: {
                            marginLeft: '-1.5rem',
                            marginTop: '1rem',
                            marginBottom: '1rem',
                        },
                        headerStyle: {
                            backgroundColor: `${theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]}`,
                            color: theme.palette.grey[500],
                        },
                        showTextRowsSelected: false,
                    }}
                    isLoading={props.isLoading}
                />
            </CardContent>
        </Card>
    );
};

const useStyles = makeStyles<IAdminUsersTableProps>()((theme, props) => ({
    TableButtons: {
        display: 'inline-block',
        padding: 0,
        minHeight: 0,
        minWidth: 0,
    },
}));

export { AdminUsersTable };
