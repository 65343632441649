import { Button, Card, CardActions, CardContent, Grid, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { makeStyles } from '../../Themes/MakeStyles';
import { IWorkstationSizeChoice, WorkstationSizeCard } from './WorkstationSizeCard';

interface IWorkstationSizeSelectorProps {
    onClickConfirmSelection: () => void;
    selectedWorkstationSize: string;
    onChangeSelectedWorkstationSize: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeOperatingSystem: (event: React.MouseEvent<HTMLElement>, os: string | null) => void;
    operatingSystem: string;
}

const windowsSizeSelections: IWorkstationSizeChoice[] = [
    {
        size: 'Radeon Large',
        price: '577.02',
        vcpus: '16vCPUs',
        ram: '64GB RAM',
        gpu: '1GPU',
        memory: '8GB GPU Memory',
    },
    {
        size: 'Radeon XL',
        price: '1074.68',
        vcpus: '32vCPUs',
        ram: '128GB RAM',
        gpu: '2GPU',
        memory: '16GB GPU Memory',
    },
    {
        size: 'Radeon Extreme',
        price: '1,775.00',
        vcpus: '64vCPUs',
        ram: '256GB RAM',
        gpu: '4GPU',
        memory: '32GB GPU Memory',
    },
    {
        size: 'Nvidia Small',
        price: '330.56',
        vcpus: '4vCPUs',
        ram: '16GB RAM',
        gpu: '16GB GPU',
        memory: '125GB HDD',
    },
    {
        size: 'Nvidia Medium',
        price: '450.76',
        vcpus: '8vCPUs',
        ram: '32GB RAM',
        gpu: '16GB GPU',
        memory: '225GB HDD',
    },
    {
        size: 'Nvidia Large',
        price: '691.16',
        vcpus: '16vCPUs',
        ram: '64GB RAM',
        gpu: '16GB GPU',
        memory: '225GB HDD',
    },
    {
        size: 'Nvidia XL',
        price: '1,171.92',
        vcpus: '32vCPUs',
        ram: '128GB RAM',
        gpu: '16GB GPU',
        memory: '900GB HDD',
    },
    {
        size: 'Nvidia Mega',
        price: '1,810.76',
        vcpus: '48vCPUs',
        ram: '192GB RAM',
        gpu: '64GB GPU',
        memory: '900GB HDD',
    },
    {
        size: 'Nvidia Extreme',
        price: '3,157.16',
        vcpus: '96vCPUs',
        ram: '348GB RAM',
        gpu: '128GB GPU',
        memory: '1800GB HDD',
    },
];

const linuxSizeSelections: IWorkstationSizeChoice[] = [
    {
        size: 'Radeon Large',
        price: '410.50',
        vcpus: '16vCPUs',
        ram: '64GB RAM',
        gpu: '1GPU',
        memory: '8GB GPU Memory',
    },
    {
        size: 'Radeon XL',
        price: '750.84',
        vcpus: '32vCPUs',
        ram: '128GB RAM',
        gpu: '2GPU',
        memory: '16GB GPU Memory',
    },
    {
        size: 'Radeon Extreme',
        price: '1,127.32',
        vcpus: '64vCPUs',
        ram: '256GB RAM',
        gpu: '4GPU',
        memory: '32GB GPU Memory',
    },
    {
        size: 'Nvidia Small',
        price: '290.08',
        vcpus: '4vCPUs',
        ram: '16GB RAM',
        gpu: '16GB GPU',
        memory: '125GB HDD',
    },
    {
        size: 'Nvidia Medium',
        price: '369.80',
        vcpus: '8vCPUs',
        ram: '32GB RAM',
        gpu: '16GB GPU',
        memory: '225GB HDD',
    },
    {
        size: 'Nvidia Large',
        price: '529.40',
        vcpus: '16vCPUs',
        ram: '64GB RAM',
        gpu: '16GB GPU',
        memory: '225GB HDD',
    },
    {
        size: 'Nvidia XL',
        price: '848.08',
        vcpus: '32vCPUs',
        ram: '128GB RAM',
        gpu: '16GB GPU',
        memory: '900GB HDD',
    },
    {
        size: 'Nvidia Mega',
        price: '1,325.00',
        vcpus: '48vCPUs',
        ram: '192GB RAM',
        gpu: '64GB GPU',
        memory: '900GB HDD',
    },
    {
        size: 'Nvidia Extreme',
        price: '2,185.64',
        vcpus: '96vCPUs',
        ram: '348GB RAM',
        gpu: '128GB GPU',
        memory: '1800GB HDD',
    },
];

const WorkstationSizeSelector: React.FC<IWorkstationSizeSelectorProps> = (props) => {
    const { classes, cx } = useStyles();

    return (
        <Card classes={{ root: classes.Card }} elevation={0}>
            <CardContent>
                <Grid className={cx(classes.Grid)} spacing={1} container direction='column' justifyContent='flex-start' alignItems='center'>
                    <Grid item xs={12}>
                        <Typography variant={'h5'}>Pick your workstation size</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>All SKUs are available in Windows or Linux. Pricing is based on 220 hours of use per/month.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleButtonGroup
                            color={'primary'}
                            value={props.operatingSystem}
                            exclusive
                            onChange={props.onChangeOperatingSystem}
                            aria-label='text alignment'
                        >
                            <ToggleButton className={cx(classes.SelectButtons)} value='windows' aria-label='left aligned'>
                                Windows
                            </ToggleButton>
                            <ToggleButton className={cx(classes.SelectButtons)} value='linux' aria-label='centered'>
                                Linux
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid spacing={1} item container direction='row' justifyContent='center' alignItems='center'>
                        {props.operatingSystem === 'windows'
                            ? windowsSizeSelections.map((sizeSelection, index) => (
                                  <Grid key={index} item xs={4}>
                                      <WorkstationSizeCard
                                          selectedWorkstationSize={props.selectedWorkstationSize}
                                          onChangeSelectedWorkstationSize={props.onChangeSelectedWorkstationSize}
                                          workstationSizeChoice={sizeSelection}
                                      />
                                  </Grid>
                              ))
                            : linuxSizeSelections.map((sizeSelection, index) => (
                                  <Grid key={index} item xs={4}>
                                      <WorkstationSizeCard
                                          selectedWorkstationSize={props.selectedWorkstationSize}
                                          onChangeSelectedWorkstationSize={props.onChangeSelectedWorkstationSize}
                                          workstationSizeChoice={sizeSelection}
                                      />
                                  </Grid>
                              ))}
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                    <Grid item>
                        <Button onClick={props.onClickConfirmSelection} variant={'contained'}>
                            Confirm Selection
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

const useStyles = makeStyles()((theme) => ({
    Card: {
        padding: '2rem',
    },
    SelectButtons: {
        textTransform: 'none',
        borderRadius: '2px',
    },
    Grid: {
        maxWidth: '54rem',
    },
}));

export { WorkstationSizeSelector };
