import { AppBar, Button, Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { Authentication, WorkstationDetailsAtom } from '../../Recoil/Atoms';
import { useState } from 'react';
import { ProfileDrawer, User } from '../ProfileDrawer/ProfileDrawer';
import { makeStyles } from '../../Themes/MakeStyles';
import { BRANDS, getBranding } from '../../Themes/Branding';
import { RouteUrls } from '../../Routes/RouteUrls';
import { AccountCircle, ArrowDropDownOutlined, ExitToAppOutlined, LaptopOutlined, Person } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface IHeaderProps {}

const Header: React.FC<IHeaderProps> = (props) => {
    const workstationDetails = useRecoilValue(WorkstationDetailsAtom);
    const authorized = useRecoilValue(Authentication);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showProfileDrawer, setShowProfileDrawer] = useState(false);
    const { classes, cx } = useStyles();
    const navigate = useNavigate();

    const user = new User({
        userName: workstationDetails && workstationDetails.userName,
        email: workstationDetails && workstationDetails.email,
        company: workstationDetails && workstationDetails.company,
    });

    const onLogoutClicked = () => {
        window.location.assign(
            `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}/oauth2/logout?post_logout_redirect_uri=${process.env.REACT_APP_VANITY_URL}/logout`
        );
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const onClickProfileMenu = () => {
        handleCloseMenu();
        setShowProfileDrawer(true);
    };

    const onClickProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onClickAdvancedConfiguration = () => {
        navigate(RouteUrls.AdvancedConfiguration);
    };

    const getMenuItems = (): JSX.Element[] => {
        let menuItems = [];

        menuItems.push(
            <MenuItem key={menuItems.length} onClick={onClickProfileMenu}>
                <ListItemIcon>
                    <Person className={cx(classes.MenuIcon)} />
                </ListItemIcon>
                <ListItemText primary='Profile' />
            </MenuItem>
        );

        menuItems.push(<Divider key={menuItems.length} />);

        if (process.env.REACT_APP_BRANDING === BRANDS.Atelier) {
            menuItems.push(
                <MenuItem key={menuItems.length} onClick={onClickAdvancedConfiguration}>
                    <ListItemIcon>
                        <LaptopOutlined className={cx(classes.MenuIcon)} />
                    </ListItemIcon>
                    <ListItemText primary='Advanced Configuration' />
                </MenuItem>
            );

            menuItems.push(<Divider key={menuItems.length} />);
        }

        menuItems.push(
            <MenuItem key={menuItems.length} onClick={onLogoutClicked}>
                <ListItemIcon>
                    <ExitToAppOutlined className={cx(classes.MenuIcon)} />
                </ListItemIcon>
                <ListItemText primary='Logout' />
            </MenuItem>
        );

        return menuItems;
    };

    return (
        <AppBar position={'static'} elevation={0} color={'transparent'}>
            <ProfileDrawer user={user} show={showProfileDrawer} onClose={() => setShowProfileDrawer(false)} />
            <Grid justifyContent={'space-between'} container className={cx(classes.ContentContainer)}>
                <Grid className={cx(classes.Logo)} item>
                    {getBranding().Icon}
                </Grid>
                {authorized && (
                    <Grid item>
                        <Button variant='text' aria-controls='profile-dropdown' aria-haspopup='true' onClick={onClickProfile}>
                            <AccountCircle className={cx(classes.AccountIcon)} />
                            {user.userName}
                            <ArrowDropDownOutlined className={cx(classes.DropdownIcon)} />
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Menu id='profile-dropdown' anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleCloseMenu}>
                {getMenuItems()}
            </Menu>
        </AppBar>
    );
};

const useStyles = makeStyles()((theme) => ({
    ContentContainer: {
        marginBottom: '5rem',
    },
    Logo: {
        height: '2rem',
        margin: '1rem',
    },
    AccountIcon: {
        fill: theme.palette.primary.main,
        marginRight: '.5rem',
    },
    DropdownIcon: {
        marginLeft: '.5rem',
    },
    MenuIcon: {
        fill: theme.palette.common.black,
    },
}));

export default Header;
