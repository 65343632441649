import { atom, atomFamily, selectorFamily } from 'recoil';
import {
    Authenticate,
    AuthenticateResponse,
    Workstation,
    WorkstationDetails,
    ListWorkstations as ListWorkstationsRequest,
    ListUsers as ListUsersRequest,
    User,
} from '../ServiceStack/ServiceStack.dtos';
import { JsonServiceClient } from '@servicestack/client';
import { SnackbarProps } from '@mui/material/Snackbar/Snackbar';
import { AlertProps } from '@mui/material/Alert/Alert';
import { uuidv4 } from '../Utility/Utility';

const getClient = () => {
    const client = new JsonServiceClient(process.env.REACT_APP_BASE_URL);
    client.useTokenCookie = true;

    return client;
};

export type SnackBarOptions = {
    snackBarProps: Omit<SnackbarProps, 'onClose' | 'anchorOrigin'>;
    alertProps: Omit<AlertProps, 'onClose'>;
    message: string;
};

export const SnackBarOptions = atom<SnackBarOptions>({
    key: 'SnackBarOptions',
    default: {
        snackBarProps: {},
        alertProps: {},
        message: '',
    },
});

export const BackButtonText = atom<{ text: string; link: string }>({
    key: 'BackButtonText',
    default: undefined,
});

export const ServiceStackClient = atom({
    key: 'ServiceStackClient',
    default: getClient(),
    dangerouslyAllowMutability: true,
});

export const WorkstationLandingLoading = atom({
    key: 'WorkstationLandingLoading',
    default: true,
});

export const WorkstationDetailsAtom = atom<WorkstationDetails>({
    key: 'WorkstationDetailsAtom',
    default: undefined,
});

export const Authentication = atom<AuthenticateResponse>({
    key: 'Authentication',
    default: undefined,
});

export const ListWorkstationsId = atomFamily({
    key: 'ListWorkstationsId',
    default: '',
});

export const ListWorkstations = selectorFamily<Workstation[], string>({
    dangerouslyAllowMutability: true, //done to allow material-table to work
    key: 'ListWorkstations',
    get:
        (id) =>
        async ({ get }) => {
            if (id !== undefined) {
                get(ListWorkstationsId(id)); // Add request ID as a dependency
                const client = await getClient();
                const request = new ListWorkstationsRequest({ managed: true });
                const response = await client.get(request);
                return response.results;
            } else {
                return [];
            }
        },
});

export const ListUsersId = atomFamily({
    key: 'ListUsersId',
    default: '',
});

export const ListUsers = selectorFamily<User[], string>({
    dangerouslyAllowMutability: true, //done to allow material-table to work
    key: 'ListUsers',
    get:
        (id) =>
        async ({ get }) => {
            if (id !== undefined) {
                get(ListUsersId(id)); // Add request ID as a dependency
                const client = await getClient();

                const request = new ListUsersRequest();
                const response = await client.get(request);
                return response.results;
            } else {
                return [];
            }
        },
});
