import { createTheme } from '@mui/material';
import { VegaPalette } from './VegaPalette';
import { VegaTypography } from './VegaTypography';
import { CommonThemeOverrides } from '../CommonThemeOverrides';

export const VegaTheme = createTheme({
    components: CommonThemeOverrides,
    palette: VegaPalette,
    typography: VegaTypography,
    spacing: 8,
});
