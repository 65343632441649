import { VegaStickleyPalette } from '../../Themes/VegaStickleyTheme/VegaStickleyPalette';
import { Typography } from '@mui/material';
import { WorkstationStatus } from '../../ServiceStack/ServiceStack.dtos';
import { makeStyles } from '../../Themes/MakeStyles';

export interface IWorkloadStatusIndicatorProps {
    workloadStatus: WorkstationStatus;
    className?: string;
}

const WorkloadStatusIndicator: React.FC<IWorkloadStatusIndicatorProps> = (props) => {
    const { classes, cx } = useStyles();

    const getStatusColor = (): string => {
        switch (props.workloadStatus) {
            case WorkstationStatus.Stopped:
                return VegaStickleyPalette.secondary.main;
            case WorkstationStatus.Running:
                return VegaStickleyPalette.primary.main;
            case WorkstationStatus.None:
            case WorkstationStatus.Stopping:
            case WorkstationStatus.Starting:
            case WorkstationStatus.Modifying:
                return VegaStickleyPalette.text.secondary;
        }
    };

    const getTextStatusColor = (): 'primary' | 'secondary' | 'textSecondary' => {
        switch (props.workloadStatus) {
            case WorkstationStatus.Stopped:
                return 'secondary';
            case WorkstationStatus.Running:
                return 'primary';
            case WorkstationStatus.None:
            case WorkstationStatus.Stopping:
            case WorkstationStatus.Starting:
            case WorkstationStatus.Modifying:
                return 'textSecondary';
        }
    };

    return (
        <div className={cx(classes.ContentContainer, props.className ? props.className : '')}>
            <div style={{ backgroundColor: getStatusColor() }} className={cx(classes.DotIndicator)} />
            <Typography variant={'body1'} color={getTextStatusColor()}>
                {props.workloadStatus}
            </Typography>
        </div>
    );
};

const useStyles = makeStyles()((theme) => ({
    DotIndicator: {
        height: '0.5rem',
        width: '0.5rem',
        borderRadius: '50%',
        marginRight: '.5rem',
        display: 'inline-block',
    },
    ContentContainer: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export { WorkloadStatusIndicator };
