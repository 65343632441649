import React from 'react';
import MaterialTable from 'material-table';
import { Paper, useTheme } from '@mui/material';
import { makeStyles } from '../../Themes/MakeStyles';
import { tableIcons } from './TableIcons';

export interface IMaterialTableLoadingSkeletonProps {}

const MaterialTableLoadingSkeleton: React.FC<IMaterialTableLoadingSkeletonProps> = (props) => {
    const styles = useStyles(props);
    const theme = useTheme();

    return (
        <MaterialTable
            icons={tableIcons}
            components={{
                Container: (props) => <Paper {...props} elevation={0} />,
            }}
            isLoading={true}
            columns={[]}
            data={[]}
            options={{
                showTitle: false,
                searchFieldAlignment: 'left',
                searchFieldVariant: 'outlined',
                searchFieldStyle: {
                    marginLeft: '-1.5rem',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    width: '150%',
                },
                headerStyle: {
                    backgroundColor: `${theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]}`,
                    color: theme.palette.grey[500],
                },
                selection: true,
                showTextRowsSelected: false,
            }}
        />
    );
};

const useStyles = makeStyles<IMaterialTableLoadingSkeletonProps>()((theme, props) => ({}));

export { MaterialTableLoadingSkeleton };
