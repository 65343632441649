import React, { Suspense, useEffect } from 'react';
import { AdminAppLayout } from '../Components/AppLayout/AdminAppLayout';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RouteUrls } from './RouteUrls';
import { RoutePrivate } from './RoutePrivate';
import { WorkstationLanding } from '../Components/WorkstationLanding/WorkstationLanding';
import { AdminHealth } from '../Components/Admin/AdminHealth/AdminHealth';
import { AdminWorkstationsController } from '../Components/Admin/AdminWorkstations/AdminWorkstationsController';
import { AdminUsersController } from '../Components/Admin/AdminUsers/AdminUsersController';
import { AdminUsersTable } from '../Components/Admin/AdminUsers/AdminUsersTable/AdminUsersTable';
import { AdminWorkstationsTable } from '../Components/Admin/AdminWorkstations/AdminWorkstationsTable/AdminWorkstationsTable';
import { AdminWorkstationDetailsController } from '../Components/Admin/AdminWorkstations/AdminWorkstationDetails/AdminWorkstationDetailsController';
import { AdminUserDetailsController } from '../Components/Admin/AdminUsers/AdminUserDetails/AdminUserDetailsController';
import { getBranding } from '../Themes/Branding';
import { makeStyles } from '../Themes/MakeStyles';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Authentication, ServiceStackClient } from '../Recoil/Atoms';
import { Authenticate } from '../ServiceStack/ServiceStack.dtos';
import { JsonServiceClient } from '@servicestack/client';
import { AdminHealthController } from '../Components/Admin/AdminHealth/AdminHealthController';

export interface IAdminUserRoutesProps {}

const AdminUserRoutes: React.FC<IAdminUserRoutesProps> = (props) => {
    const styles = useStyles();
    const [authenticationResponse, setAuthenticationResponse] = useRecoilState(Authentication);

    //ToDo is there a better way to check auth status? MPG
    useEffect(() => {
        if (authenticationResponse === undefined) {
            checkAuthStatus().then((r) => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkAuthStatus = async () => {
        const client = new JsonServiceClient(process.env.REACT_APP_BASE_URL);
        client
            .post(new Authenticate({ rememberMe: true }))
            .then((response) => {
                console.log(response);
                client.useTokenCookie = true;
                setAuthenticationResponse(response);
            })
            .catch((error) => console.log(error));
    };

    return (
        <AdminAppLayout>
            <Routes>
                <Route
                    path={RouteUrls.WorkRemoteLanding}
                    element={
                        <RoutePrivate>
                            <div className={styles.classes.WorkStationLandingBackground}>
                                <WorkstationLanding />
                            </div>
                        </RoutePrivate>
                    }
                />
                <Route
                    path={RouteUrls.AdminHealth}
                    element={
                        <RoutePrivate>
                            <Suspense fallback={<AdminHealth isLoading={true} workstations={[]} />}>
                                <AdminHealthController />
                            </Suspense>
                        </RoutePrivate>
                    }
                />
                <Route
                    path={RouteUrls.AdminWorkstations}
                    element={
                        <RoutePrivate>
                            <Suspense fallback={<AdminWorkstationsTable workstations={[]} isLoading={true} />}>
                                <AdminWorkstationsController isLoading={false} />
                            </Suspense>
                        </RoutePrivate>
                    }
                />
                <Route
                    path={`${RouteUrls.AdminWorkstations}/:machineId/:tab`}
                    element={
                        <RoutePrivate>
                            <AdminWorkstationDetailsController />
                        </RoutePrivate>
                    }
                />
                <Route
                    path={`${RouteUrls.AdminUsers}/:userId`}
                    element={
                        <RoutePrivate>
                            <AdminUserDetailsController />
                        </RoutePrivate>
                    }
                />
                <Route
                    path={RouteUrls.AdminUsers}
                    element={
                        <RoutePrivate>
                            <Suspense fallback={<AdminUsersTable users={[]} isLoading={true} />}>
                                <AdminUsersController isLoading={false} />
                            </Suspense>
                        </RoutePrivate>
                    }
                />
            </Routes>
        </AdminAppLayout>
    );
};

const useStyles = makeStyles()((theme) => ({
    WorkStationLandingBackground: getBranding().WorkstationLandingBackground,
}));

export { AdminUserRoutes };
