/* Options:
Date: 2022-07-14 16:29:39
Version: 6.10
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:5001/prod

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/

export interface IReturn<T> {
    createResponse(): T;
}

export interface IReturnVoid {
    createResponse(): void;
}

export interface IHasSessionId {
    sessionId: string;
}

export interface IHasBearerToken {
    bearerToken: string;
}

export interface IPost {}

export class TeradiciADComputer {
    public createdBy: string;
    public createdOn: string;
    public depolymentId: string;
    public operatingSystem: string;
    public operatingSystemVersion: string;
    public computerName: string;
    public computerHostname: string;

    public constructor(init?: Partial<TeradiciADComputer>) {
        (Object as any).assign(this, init);
    }
}

export class WorkstationUser {
    public userName: string;
    public userGuid: string;

    public constructor(init?: Partial<WorkstationUser>) {
        (Object as any).assign(this, init);
    }
}

export class Workstation {
    public name: string;
    public ipAddress: string;
    public users: WorkstationUser[];
    public cloudProvider: string;
    public size: string;
    public status: string;
    public machineId: string;
    public createdOn: string;
    public lastModifiedOn: string;
    public instanceId: string;
    public region: string;
    public managedByCasManager: boolean;

    public constructor(init?: Partial<Workstation>) {
        (Object as any).assign(this, init);
    }
}

// @DataContract
export class ResponseError {
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string };

    public constructor(init?: Partial<ResponseError>) {
        (Object as any).assign(this, init);
    }
}

// @DataContract
export class ResponseStatus {
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string };

    public constructor(init?: Partial<ResponseStatus>) {
        (Object as any).assign(this, init);
    }
}

export class UserWorkstation {
    public workstationId: string;
    public workstationName: string;
    public assignedOn: string;
    public provider: string;
    public size: string;
    public entitlementId: string;

    public constructor(init?: Partial<UserWorkstation>) {
        (Object as any).assign(this, init);
    }
}

export class User {
    public userGuid: string;
    public userName: string;
    public fullName: string;
    public emailAddress: string;
    public deploymentId: string;
    public createdOn: string;
    public status: string;
    public workstations: UserWorkstation[];

    public constructor(init?: Partial<User>) {
        (Object as any).assign(this, init);
    }
}

export class TeradiciOsInfo {
    public publisher: string;
    public offer: string;
    public sku: string;
    public version: string;

    public constructor(init?: Partial<TeradiciOsInfo>) {
        (Object as any).assign(this, init);
    }
}

export class TeradiciProvisioningStatus {
    public constructor(init?: Partial<TeradiciProvisioningStatus>) {
        (Object as any).assign(this, init);
    }
}

export class TeradiciEntitlementResource {
    public machineId: string;
    public machineName: string;
    public deploymentId: string;
    public connectorId: string;
    public resourceGroup: string;
    public subscriptionId: string;
    public powerState: string;
    public powerStateLastChangedOn: string;
    public createdBy: string;
    public createdOn: string;
    public updatedOn: string;
    public active: boolean;
    public managed: boolean;
    public location: string;
    public vmSize: string;
    public osInfo: TeradiciOsInfo;
    public provisioningStatus: TeradiciProvisioningStatus;

    public constructor(init?: Partial<TeradiciEntitlementResource>) {
        (Object as any).assign(this, init);
    }
}

export class TeradiciEntitlement {
    public createdBy: string;
    public createdOn: string;
    public deploymentId: string;
    public entitlementId: string;
    public resource: TeradiciEntitlementResource;
    public updatedOn: string;
    public resourceId: string;
    public userGuid: string;
    public resourceName: string;
    public resourceType: string;
    public status: string;

    public constructor(init?: Partial<TeradiciEntitlement>) {
        (Object as any).assign(this, init);
    }
}

export enum MachineStatus {
    success = 'success',
    fail = 'fail',
}

export class CreateEntitlementResponse {
    public createdBy: string;
    public createdOn: string;
    public updatedOn: string;
    public deploymentId: string;
    public entitlementId: string;
    public machineId: string;
    public userGuid: string;

    public constructor(init?: Partial<CreateEntitlementResponse>) {
        (Object as any).assign(this, init);
    }
}

export class WorkstationActionExecutionResult {
    public id: string;

    public constructor(init?: Partial<WorkstationActionExecutionResult>) {
        (Object as any).assign(this, init);
    }
}

export class WorkstationDetails {
    public userName: string;
    public hostAddress: string;
    public cloudProviderWorkstationId: string;
    public email: string;
    public company: string;
    public workstationDescription: string;
    public qumuloNodes: string;

    public constructor(init?: Partial<WorkstationDetails>) {
        (Object as any).assign(this, init);
    }
}

export enum WorkstationStatus {
    None = 'None',
    Stopping = 'Stopping',
    Starting = 'Starting',
    Running = 'Running',
    Stopped = 'Stopped',
    Modifying = 'Modifying',
}

export class WorkstationDetailsDb {
    public adEmail: string;
    public workloadId: string;
    public cloudProviderWorkstationId: string;
    public securityGroupId: string;
    public hostAddress: string;
    public company: string;
    public workstationDescription: string;
    public ownerEmail: string;
    public region: string;
    public qumuloNodes: string;

    public constructor(init?: Partial<WorkstationDetailsDb>) {
        (Object as any).assign(this, init);
    }
}

export class TeradiciMachineAPIStatus {
    public constructor(init?: Partial<TeradiciMachineAPIStatus>) {
        (Object as any).assign(this, init);
    }
}

export class ListWorkstationsResponse {
    public results: Workstation[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListWorkstationsResponse>) {
        (Object as any).assign(this, init);
    }
}

export class ListUsersResponse {
    public results: User[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListUsersResponse>) {
        (Object as any).assign(this, init);
    }
}

export class ListADComputersResponse {
    public results: TeradiciADComputer[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListADComputersResponse>) {
        (Object as any).assign(this, init);
    }
}

export class ListEntitlementsResponse {
    public results: TeradiciEntitlement[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListEntitlementsResponse>) {
        (Object as any).assign(this, init);
    }
}

export class StartMachinesResponse {
    public results: MachineStatus[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<StartMachinesResponse>) {
        (Object as any).assign(this, init);
    }
}

export class StopMachinesResponse {
    public results: MachineStatus[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<StopMachinesResponse>) {
        (Object as any).assign(this, init);
    }
}

export class AddExistingWorkstationsResponse {
    public results: string[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AddExistingWorkstationsResponse>) {
        (Object as any).assign(this, init);
    }
}

export class RemoveWorkstationsResponse {
    public results: string[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RemoveWorkstationsResponse>) {
        (Object as any).assign(this, init);
    }
}

export class RemoveEntitlementsResponse {
    public results: string[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RemoveEntitlementsResponse>) {
        (Object as any).assign(this, init);
    }
}

export class AddUserEntitlementsResponse {
    public results: CreateEntitlementResponse[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AddUserEntitlementsResponse>) {
        (Object as any).assign(this, init);
    }
}

export class StopWorkstationResponse {
    public result: WorkstationActionExecutionResult;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<StopWorkstationResponse>) {
        (Object as any).assign(this, init);
    }
}

export class StartQumuloClusterResponse {
    public result: WorkstationActionExecutionResult;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<StartQumuloClusterResponse>) {
        (Object as any).assign(this, init);
    }
}

export class StopQumuloClusterResponse {
    public result: WorkstationActionExecutionResult;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<StopQumuloClusterResponse>) {
        (Object as any).assign(this, init);
    }
}

export class WhitelistIpAddressResponse {
    public result: WorkstationActionExecutionResult;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<WhitelistIpAddressResponse>) {
        (Object as any).assign(this, init);
    }
}

export class GetWorkstationDetailsResponse {
    public result: WorkstationDetails;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetWorkstationDetailsResponse>) {
        (Object as any).assign(this, init);
    }
}

export class GetWorkstationStatusResponse {
    public result: WorkstationStatus;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetWorkstationStatusResponse>) {
        (Object as any).assign(this, init);
    }
}

export class GetQumuloClusterStatusResponse {
    public result: WorkstationStatus;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetQumuloClusterStatusResponse>) {
        (Object as any).assign(this, init);
    }
}

export class GetWorkstationDetailTagsResponse {
    public result: WorkstationDetailsDb;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetWorkstationDetailTagsResponse>) {
        (Object as any).assign(this, init);
    }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string };

    public constructor(init?: Partial<AuthenticateResponse>) {
        (Object as any).assign(this, init);
    }
}

// @DataContract
export class AssignRolesResponse {
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) {
        (Object as any).assign(this, init);
    }
}

// @DataContract
export class UnAssignRolesResponse {
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) {
        (Object as any).assign(this, init);
    }
}

/**
 * ListWorkstations
 */
// @Route("/admin/workstations/list", "GET")
// @Api(Description="ListWorkstations")
export class ListWorkstations implements IReturn<ListWorkstationsResponse> {
    /**
     * bool that will return machines that are managed
     */
    // @ApiMember(DataType="boolean", Description="bool that will return machines that are managed", IsRequired=true, Name="Managed")
    public managed: boolean;

    public constructor(init?: Partial<ListWorkstations>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'ListWorkstations';
    }
    public getMethod() {
        return 'GET';
    }
    public createResponse() {
        return new ListWorkstationsResponse();
    }
}

/**
 * ListUsers
 */
// @Route("/admin/users/list", "GET")
// @Api(Description="ListUsers")
export class ListUsers implements IReturn<ListUsersResponse> {
    public constructor(init?: Partial<ListUsers>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'ListUsers';
    }
    public getMethod() {
        return 'GET';
    }
    public createResponse() {
        return new ListUsersResponse();
    }
}

/**
 * ListADComputers
 */
// @Route("/admin/ad-computers/list", "Get")
// @Api(Description="ListADComputers")
export class ListADComputers implements IReturn<ListADComputersResponse> {
    /**
     * Boolean to query whether the computers have been added to Teradici or not.
     */
    // @ApiMember(DataType="boolean", Description="Boolean to query whether the computers have been added to Teradici or not.", IsRequired=true, Name="FilterAdded")
    public filterAdded: boolean;

    public constructor(init?: Partial<ListADComputers>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'ListADComputers';
    }
    public getMethod() {
        return 'GET';
    }
    public createResponse() {
        return new ListADComputersResponse();
    }
}

/**
 * ListEntitlements
 */
// @Route("/admin/entitlements/list", "GET")
// @Api(Description="ListEntitlements")
export class ListEntitlements implements IReturn<ListEntitlementsResponse> {
    public constructor(init?: Partial<ListEntitlements>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'ListEntitlements';
    }
    public getMethod() {
        return 'GET';
    }
    public createResponse() {
        return new ListEntitlementsResponse();
    }
}

/**
 * StartMachines
 */
// @Route("/admin/workstations/start", "POST")
// @Api(Description="StartMachines")
export class StartMachines implements IReturn<StartMachinesResponse> {
    /**
     * Id of Machine(s) that are being started
     */
    // @ApiMember(DataType="array", Description="Id of Machine(s) that are being started", IsRequired=true, Name="MachineIds")
    public machineIds: string[];

    public constructor(init?: Partial<StartMachines>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'StartMachines';
    }
    public getMethod() {
        return 'POST';
    }
    public createResponse() {
        return new StartMachinesResponse();
    }
}

/**
 * StopMachines
 */
// @Route("/admin/workstations/stop", "POST")
// @Api(Description="StopMachines")
export class StopMachines implements IReturn<StopMachinesResponse> {
    /**
     * Id of Machine(s) that are being stopped
     */
    // @ApiMember(DataType="array", Description="Id of Machine(s) that are being stopped", IsRequired=true, Name="MachineIds")
    public machineIds: string[];

    public constructor(init?: Partial<StopMachines>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'StopMachines';
    }
    public getMethod() {
        return 'POST';
    }
    public createResponse() {
        return new StopMachinesResponse();
    }
}

/**
 * AddExistingWorkstations
 */
// @Route("/admin/workstations/add", "POST")
// @Api(Description="AddExistingWorkstations")
export class AddExistingWorkstations implements IReturn<AddExistingWorkstationsResponse> {
    /**
     * Workstation being added
     */
    // @ApiMember(DataType="array", Description="Workstation being added", Name="TeradiciAdComputers")
    public teradiciAdComputers: TeradiciADComputer[];

    public constructor(init?: Partial<AddExistingWorkstations>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'AddExistingWorkstations';
    }
    public getMethod() {
        return 'POST';
    }
    public createResponse() {
        return new AddExistingWorkstationsResponse();
    }
}

/**
 * RemoveWorkstations
 */
// @Route("/admin/workstations/remove", "DELETE")
// @Api(Description="RemoveWorkstations")
export class RemoveWorkstations implements IReturn<RemoveWorkstationsResponse> {
    /**
     * List of Machine ids to be removed
     */
    // @ApiMember(DataType="array", Description="List of Machine ids to be removed", IsRequired=true, Name="MachineIds")
    public machineIds: string[];

    public constructor(init?: Partial<RemoveWorkstations>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'RemoveWorkstations';
    }
    public getMethod() {
        return 'DELETE';
    }
    public createResponse() {
        return new RemoveWorkstationsResponse();
    }
}

/**
 * RemoveEntitlements
 */
// @Route("/admin/entitlements/remove", "DELETE")
// @Api(Description="RemoveEntitlements")
export class RemoveEntitlements implements IReturn<RemoveEntitlementsResponse> {
    /**
     * Ids of entitlement that are being removed
     */
    // @ApiMember(DataType="array", Description="Ids of entitlement that are being removed", IsRequired=true, Name="EntitlementIds")
    public entitlementIds: string[];

    public constructor(init?: Partial<RemoveEntitlements>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'RemoveEntitlements';
    }
    public getMethod() {
        return 'DELETE';
    }
    public createResponse() {
        return new RemoveEntitlementsResponse();
    }
}

/**
 * AddUserEntitlements
 */
// @Route("/admin/entitlements/add", "POST")
// @Api(Description="AddUserEntitlements")
export class AddUserEntitlements implements IReturn<AddUserEntitlementsResponse> {
    /**
     * Id of machine to be entitled
     */
    // @ApiMember(DataType="string", Description="Id of machine to be entitled", IsRequired=true, Name="MachineId")
    public machineId: string;

    /**
     * Id of users that are receiving entitlement
     */
    // @ApiMember(DataType="array", Description="Id of users that are receiving entitlement", IsRequired=true, Name="UserGuidList")
    public userGuidList: string[];

    public constructor(init?: Partial<AddUserEntitlements>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'AddUserEntitlements';
    }
    public getMethod() {
        return 'POST';
    }
    public createResponse() {
        return new AddUserEntitlementsResponse();
    }
}

/**
 * ContactSupport
 */
// @Route("/support", "POST")
// @Api(Description="ContactSupport")
export class ContactSupport implements IReturnVoid, IPost {
    /**
     * User's First Name
     */
    // @ApiMember(DataType="string", Description="User's First Name", IsRequired=true, Name="FirstName")
    public firstName: string;

    /**
     * User's Last Name
     */
    // @ApiMember(DataType="string", Description="User's Last Name", IsRequired=true, Name="LastName")
    public lastName: string;

    /**
     * User's Email Address
     */
    // @ApiMember(DataType="string", Description="User's Email Address", IsRequired=true, Name="Email")
    public email: string;

    /**
     * User's Feedback
     */
    // @ApiMember(DataType="string", Description="User's Feedback", IsRequired=true, Name="Feedback")
    public feedback: string;

    /**
     * Subject
     */
    // @ApiMember(DataType="string", Description="Subject", IsRequired=true, Name="Subject")
    public subject: string;

    public constructor(init?: Partial<ContactSupport>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'ContactSupport';
    }
    public getMethod() {
        return 'POST';
    }
    public createResponse() {}
}

/**
 * CreateUser
 */
// @Route("/users/create", "POST")
// @Api(Description="CreateUser")
export class CreateUser {
    /**
     * Subject
     */
    // @ApiMember(DataType="string", Description="Subject", IsRequired=true, Name="FullName")
    public fullName: string;

    /**
     * Subject
     */
    // @ApiMember(DataType="string", Description="Subject", IsRequired=true, Name="Email")
    public email: string;

    /**
     * Subject
     */
    // @ApiMember(DataType="string", Description="Subject", IsRequired=true, Name="Password")
    public password: string;

    /**
     * Subject
     */
    // @ApiMember(DataType="string", Description="Subject", IsRequired=true, Name="MobilePhone")
    public mobilePhone: string;

    /**
     * Subject
     */
    // @ApiMember(DataType="string", Description="Subject", IsRequired=true, Name="Token")
    public token: string;

    public constructor(init?: Partial<CreateUser>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'CreateUser';
    }
    public getMethod() {
        return 'POST';
    }
    public createResponse() {}
}

/**
 * StartWorkstation
 */
// @Route("/workstation/start", "POST")
// @Api(Description="StartWorkstation")
export class StartWorkstation implements IReturn<StopWorkstationResponse>, IPost {
    public constructor(init?: Partial<StartWorkstation>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'StartWorkstation';
    }
    public getMethod() {
        return 'POST';
    }
    public createResponse() {
        return new StopWorkstationResponse();
    }
}

/**
 * StartQumuloCluster
 */
// @Route("/workstation/qumulo/start", "POST")
// @Api(Description="StartQumuloCluster")
export class StartQumuloCluster implements IReturn<StartQumuloClusterResponse>, IPost {
    public constructor(init?: Partial<StartQumuloCluster>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'StartQumuloCluster';
    }
    public getMethod() {
        return 'POST';
    }
    public createResponse() {
        return new StartQumuloClusterResponse();
    }
}

/**
 * StopWorkstation
 */
// @Route("/workstation/stop", "POST")
// @Api(Description="StopWorkstation")
export class StopWorkstation implements IReturn<StopWorkstationResponse>, IPost {
    public constructor(init?: Partial<StopWorkstation>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'StopWorkstation';
    }
    public getMethod() {
        return 'POST';
    }
    public createResponse() {
        return new StopWorkstationResponse();
    }
}

/**
 * StopQumuloCluster
 */
// @Route("/workstation/qumulo/stop", "POST")
// @Api(Description="StopQumuloCluster")
export class StopQumuloCluster implements IReturn<StopQumuloClusterResponse>, IPost {
    public constructor(init?: Partial<StopQumuloCluster>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'StopQumuloCluster';
    }
    public getMethod() {
        return 'POST';
    }
    public createResponse() {
        return new StopQumuloClusterResponse();
    }
}

/**
 * WhitelistIpAddress
 */
// @Route("/workstation/whitelist", "POST")
// @Api(Description="WhitelistIpAddress")
export class WhitelistIpAddress implements IReturn<WhitelistIpAddressResponse>, IPost {
    public constructor(init?: Partial<WhitelistIpAddress>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'WhitelistIpAddress';
    }
    public getMethod() {
        return 'POST';
    }
    public createResponse() {
        return new WhitelistIpAddressResponse();
    }
}

// @Route("/workstation/details", "GET")
export class GetWorkstationDetails implements IReturn<GetWorkstationDetailsResponse> {
    public constructor(init?: Partial<GetWorkstationDetails>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'GetWorkstationDetails';
    }
    public getMethod() {
        return 'GET';
    }
    public createResponse() {
        return new GetWorkstationDetailsResponse();
    }
}

// @Route("/workstation/status", "GET")
export class GetWorkstationStatus implements IReturn<GetWorkstationStatusResponse> {
    public constructor(init?: Partial<GetWorkstationStatus>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'GetWorkstationStatus';
    }
    public getMethod() {
        return 'GET';
    }
    public createResponse() {
        return new GetWorkstationStatusResponse();
    }
}

/**
 * GetQumuloClusterStatus
 */
// @Route("/workstation/qumulo/status", "GET")
// @Api(Description="GetQumuloClusterStatus")
export class GetQumuloClusterStatus implements IReturn<GetQumuloClusterStatusResponse> {
    public constructor(init?: Partial<GetQumuloClusterStatus>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'GetQumuloClusterStatus';
    }
    public getMethod() {
        return 'GET';
    }
    public createResponse() {
        return new GetQumuloClusterStatusResponse();
    }
}

// @Route("/workstation/tags/details", "GET")
export class GetWorkstationDetailTags implements IReturn<GetWorkstationDetailTagsResponse> {
    public constructor(init?: Partial<GetWorkstationDetailTags>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'GetWorkstationDetailTags';
    }
    public getMethod() {
        return 'GET';
    }
    public createResponse() {
        return new GetWorkstationDetailTagsResponse();
    }
}

/**
 * Sign In
 */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
    /**
     * AuthProvider, e.g. credentials
     */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string };

    public constructor(init?: Partial<Authenticate>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'Authenticate';
    }
    public getMethod() {
        return 'POST';
    }
    public createResponse() {
        return new AuthenticateResponse();
    }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost {
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string };

    public constructor(init?: Partial<AssignRoles>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'AssignRoles';
    }
    public getMethod() {
        return 'POST';
    }
    public createResponse() {
        return new AssignRolesResponse();
    }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost {
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string };

    public constructor(init?: Partial<UnAssignRoles>) {
        (Object as any).assign(this, init);
    }
    public getTypeName() {
        return 'UnAssignRoles';
    }
    public getMethod() {
        return 'POST';
    }
    public createResponse() {
        return new UnAssignRolesResponse();
    }
}
