import React, { useEffect, useState } from 'react';
import { makeStyles } from '../../../Themes/MakeStyles';
import {
    AppBar as MuiAppBar,
    Box,
    Breadcrumbs,
    Button,
    Divider,
    Grid,
    IconButton,
    Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    styled,
    Typography,
} from '@mui/material';
import { ProfileDrawer, User } from '../../ProfileDrawer/ProfileDrawer';
import { AccountCircle, ArrowBack, ArrowDropDownOutlined, Block, ExitToAppOutlined, LaptopOutlined, Person } from '@mui/icons-material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Authentication, BackButtonText, WorkstationDetailsAtom } from '../../../Recoil/Atoms';
import { Link as RouterLink, LinkProps, Router, useLocation, useNavigate } from 'react-router-dom';
import { RouteUrls } from '../../../Routes/RouteUrls';
import { cx } from 'tss-react/@emotion/css';

export interface IAdminHeaderProps {}

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    paddingLeft: '1rem',
    paddingRight: '1rem',
    zIndex: theme.zIndex.drawer + 1,
    height: '4rem',
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
    marginLeft: '16rem',
    width: `calc(100% - ${16}rem)`,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
}));

function toTitleCase(str) {
    return str.replace(/\b\w+/g, function (s) {
        return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
    });
}

const AdminHeader: React.FC<IAdminHeaderProps> = (props) => {
    const styles = useStyles(props);
    const authorized = useRecoilValue(Authentication);
    const workstationDetails = useRecoilValue(WorkstationDetailsAtom);
    const [showProfileDrawer, setShowProfileDrawer] = useState(false);
    const { classes, cx } = useStyles(props);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [backButtonText, setBackButtonText] = useRecoilState(BackButtonText);

    const onClickProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const onLogoutClicked = () => {
        window.location.assign(
            `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}/oauth2/logout?post_logout_redirect_uri=${process.env.REACT_APP_VANITY_URL}/logout`
        );
    };

    const getMenuItems = (): JSX.Element[] => {
        let menuItems = [];

        menuItems.push(
            <MenuItem key={menuItems.length} onClick={onLogoutClicked}>
                <ListItemIcon>
                    <ExitToAppOutlined className={cx(classes.MenuIcon)} />
                </ListItemIcon>
                <ListItemText primary='Logout' />
            </MenuItem>
        );

        return menuItems;
    };

    return (
        <AppBar position='absolute' elevation={0} color={'transparent'}>
            <Grid justifyContent={'space-between'} container className={cx(classes.ContentContainer)}>
                <Grid item className={cx(styles.classes.Breadcrumbs)}>
                    <Typography variant={'h6'}>{toTitleCase(toTitleCase(location.pathname.split('/')[2]))}</Typography>
                    <Grid item xs={12}>
                        {backButtonText !== undefined && (
                            <IconButton
                                className={cx(styles.classes.BackButton)}
                                onClick={() => {
                                    navigate(backButtonText.link);
                                }}
                                aria-label='delete'
                                size='large'
                            >
                                <ArrowBack fontSize='inherit' />
                                <Typography>{backButtonText.text}</Typography>
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
                {authorized && (
                    <Grid item>
                        <Button color={'inherit'} variant='text' aria-controls='profile-dropdown' aria-haspopup='true' onClick={onClickProfile}>
                            <AccountCircle className={cx(classes.AccountIcon)} />
                            {authorized && authorized.displayName}
                            <ArrowDropDownOutlined className={cx(classes.DropdownIcon)} />
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Menu id='profile-dropdown' anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleCloseMenu}>
                {getMenuItems()}
            </Menu>
        </AppBar>
    );
};

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

const useStyles = makeStyles<IAdminHeaderProps>()((theme, props) => ({
    AppBar: {
        padding: '2rem',
    },
    ContentContainer: {
        marginBottom: '5rem',
    },
    Logo: {
        height: '2rem',
        margin: '1rem',
    },
    AccountIcon: {
        marginRight: '.5rem',
    },
    DropdownIcon: {
        marginLeft: '.5rem',
    },
    MenuIcon: {
        fill: theme.palette.common.black,
    },
    Breadcrumbs: {
        marginTop: '.25rem',
        marginLeft: '.5rem',
    },
    BackButton: {
        color: 'black',
        padding: 0,
        minHeight: 0,
        minWidth: 0,
        marginLeft: '-.25rem',
    },
}));

export { AdminHeader };
