import React from 'react';
import { makeStyles } from '../../../Themes/MakeStyles';
import { Card, CardContent, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { cx } from 'tss-react/@emotion/css';
import { format } from 'date-fns';
import { Workstation } from '../../../ServiceStack/ServiceStack.dtos';

export interface IAdminHealthProps {
    isLoading: boolean;
    workstations: Workstation[];
}

const AdminHealth: React.FC<IAdminHealthProps> = (props) => {
    const styles = useStyles(props);

    return (
        <Paper elevation={0} className={cx(styles.classes.Container)}>
            <Grid item container className={cx(styles.classes.Header)} direction={'row'}>
                <Grid item xs={6}>
                    <Typography textAlign={'left'} variant={'h5'}>
                        Service Health Dashboard
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography textAlign={'right'} variant={'h5'}>{`Current Status: ${format(new Date(), 'MM/dd/yyyy')}`}</Typography>
                </Grid>
            </Grid>
            <Card>
                <CardContent className={cx(styles.classes.Card)}>
                    <Typography className={cx(styles.classes.Header)} variant={'h6'}>
                        VM Overview
                    </Typography>
                    <Grid container justifyContent={'flex-start'} direction={'row'}>
                        <Grid item xs={4}>
                            <Typography variant={'body1'}>Total VMs</Typography>
                            <Typography className={cx(styles.classes.SubText)} variant={'body1'}>
                                {props.isLoading ? <Skeleton width={'1rem'} variant='text' /> : props.workstations.length}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={'body1'}>Active VMs</Typography>
                            <Typography className={cx(styles.classes.SubText)} variant={'body1'}>
                                {props.isLoading ? (
                                    <Skeleton width={'1rem'} variant='text' />
                                ) : props.workstations.length > 0 ? (
                                    props.workstations.filter(
                                        (status) => status.status === 'running' || status.status === 'stopping' || status.status === 'starting'
                                    ).length
                                ) : (
                                    0
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={'body1'}>Inactive VMs</Typography>
                            <Typography className={cx(styles.classes.SubText)} variant={'body1'}>
                                {props.isLoading ? (
                                    <Skeleton width={'1rem'} variant='text' />
                                ) : props.workstations.length > 0 ? (
                                    props.workstations.filter((status) => status.status === 'stopped').length
                                ) : (
                                    0
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Paper>
    );
};

const useStyles = makeStyles<IAdminHealthProps>()((theme, props) => ({
    Container: {
        backgroundColor: 'transparent',
    },
    Header: {
        marginBottom: '1rem',
    },
    Card: {
        padding: '2rem',
    },
    SubText: {
        color: '#9aa8c1',
    },
}));

export { AdminHealth };
