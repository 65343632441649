import React, { useState } from 'react';
import { Alert, Button, Collapse, Dialog, DialogContent, Grid, LinearProgress, Snackbar, TextField, Typography } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ServiceStackClient, SnackBarOptions } from '../../Recoil/Atoms';
import { ContactSupport } from '../../ServiceStack/ServiceStack.dtos';
import { makeStyles } from '../../Themes/MakeStyles';
import { cx } from 'tss-react/@emotion/css';

export interface ISupportFormProps {
    show: boolean;
    onClose: () => void;
}

const SupportForm: React.FC<ISupportFormProps> = (props) => {
    const client = useRecoilValue(ServiceStackClient);
    const [sending, setSending] = useState(false);
    const styles = useStyles(props);
    const [snackBarOptions, setSnackbarOptions] = useRecoilState(SnackBarOptions);

    const sendFeedback = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        setSending(true);
        client
            .post(
                new ContactSupport({
                    firstName: data.get('firstName').toString(),
                    lastName: data.get('lastName').toString(),
                    email: data.get('email').toString(),
                    feedback: data.get('message').toString(),
                    subject: data.get('subject').toString(),
                })
            )
            .then(() => {
                setSending(false);
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message: 'Your feedback has been sent',
                });
                props.onClose();
            })
            .catch((error) => {
                setSending(false);
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message: 'There was a problem contacting support',
                });
                props.onClose();
            });
    };

    return (
        <>
            <Dialog
                open={props.show}
                onClose={() => props.onClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                onBackdropClick={props.onClose}
            >
                <Collapse in={sending}>
                    <LinearProgress variant='indeterminate' />
                </Collapse>
                <DialogContent>
                    <Grid>
                        <Typography gutterBottom variant='h5'>
                            Contact Us
                        </Typography>
                        <Typography className={cx(styles.classes.SpacingBottom)} variant='body2' color='textSecondary' component='p' gutterBottom>
                            Fill out the form and our team will get back to you within 24 hours.
                        </Typography>
                        <form onSubmit={sendFeedback}>
                            <Grid container spacing={2}>
                                <Grid xs={12} sm={6} item>
                                    <TextField
                                        placeholder='Enter first name'
                                        label='First Name'
                                        name={'firstName'}
                                        id={'firstName'}
                                        variant='outlined'
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} item>
                                    <TextField
                                        name={'lastName'}
                                        id={'lastName'}
                                        placeholder='Enter last name'
                                        label='Last Name'
                                        variant='outlined'
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name={'email'}
                                        id={'email'}
                                        type='email'
                                        placeholder='Enter email'
                                        label='Email'
                                        variant='outlined'
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name={'subject'}
                                        id={'subject'}
                                        placeholder='Enter the subject'
                                        label='Subject'
                                        variant='outlined'
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name={'message'}
                                        id={'message'}
                                        label='Message'
                                        multiline
                                        rows={4}
                                        placeholder='Type your message here'
                                        variant='outlined'
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type='submit' variant='contained' color='primary' fullWidth>
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

const useStyles = makeStyles<ISupportFormProps>()((theme, props) => ({
    SpacingBottom: {
        marginBottom: '1rem',
    },
}));

export { SupportForm };
