import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import React, { PropsWithChildren, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { RouteUrls } from './RouteUrls';
import { Authentication, ServiceStackClient, SnackBarOptions } from '../Recoil/Atoms';
import { JsonServiceClient } from '@servicestack/client';
import { Authenticate } from '../ServiceStack/ServiceStack.dtos';

export interface IRoutePrivateProps extends PropsWithChildren {
    requiredRoles?: string[];
}

export const RoutePrivate: React.FC<IRoutePrivateProps> = (props) => {
    const location = useLocation();
    const [client, setClient] = useRecoilState(ServiceStackClient);
    const [snackBarOptions, setSnackbarOptions] = useRecoilState(SnackBarOptions);
    const navigate = useNavigate();
    const [authenticationResponse, setAuthenticationResponse] = useRecoilState(Authentication);

    //Handle logout when no auth
    useEffect(() => {
        client.onAuthenticationRequired = async () => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'Your session has expired',
            });

            navigate(RouteUrls.Logout, { state: { from: { pathname: location.pathname } } });
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return authenticationResponse ? (
        <>{props.children}</>
    ) : (
        <Navigate
            to={{
                pathname: RouteUrls.Login,
            }}
            state={{ from: location.pathname }}
        />
    );
};
