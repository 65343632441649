import React, { useEffect } from 'react';
import UserAppLayout from '../Components/AppLayout/UserAppLayout';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { RouteUrls } from './RouteUrls';
import Logout from '../Components/Logout/Logout';
import Login from '../Components/Login/Login';
import { BRANDS } from '../Themes/Branding';
import Signup from '../Components/Signup/Signup';
import { RoutePrivate } from './RoutePrivate';
import { WorkstationLanding } from '../Components/WorkstationLanding/WorkstationLanding';
import AdvancedConfiguration from '../Components/AdvancedConfiguration/AdvancedConfigurationController';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Authentication, ServiceStackClient, SnackBarOptions } from '../Recoil/Atoms';

export interface INormalUserRoutesProps {}

const NormalUserRoutes: React.FC<INormalUserRoutesProps> = (props) => {
    return (
        <UserAppLayout>
            {process.env.REACT_APP_BRANDING === BRANDS.Atelier && (
                <Routes>
                    <Route path={RouteUrls.Signup} element={<Signup />} />
                </Routes>
            )}
            <Routes>
                <Route
                    path={RouteUrls.WorkRemoteLanding}
                    element={
                        <RoutePrivate>
                            <WorkstationLanding />
                        </RoutePrivate>
                    }
                />
                {process.env.REACT_APP_BRANDING === BRANDS.Atelier && (
                    <Route
                        path={RouteUrls.AdvancedConfiguration}
                        element={
                            <RoutePrivate>
                                <AdvancedConfiguration />
                            </RoutePrivate>
                        }
                    />
                )}
            </Routes>
        </UserAppLayout>
    );
};

export { NormalUserRoutes };
