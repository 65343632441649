import React, { useEffect } from 'react';
import { makeStyles } from '../../../../Themes/MakeStyles';
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Authentication, BackButtonText, ListUsers, ListWorkstations } from '../../../../Recoil/Atoms';
import { AdminUserDetails } from './AdminUserDetails';
import { RouteUrls } from '../../../../Routes/RouteUrls';
import { LocationState } from '../../../../Types/Types';

export interface IAdminUserDetailsControllerProps {}

const AdminUserDetailsController: React.FC<IAdminUserDetailsControllerProps> = (props) => {
    const styles = useStyles(props);
    const authorized = useRecoilValue(Authentication);
    const users = useRecoilValue(ListUsers(authorized ? authorized.userId : ''));
    const location = useLocation();
    const [backButtonText, setBackButtonText] = useRecoilState(BackButtonText);
    const currentUserId = location.pathname.split('/')[3];
    const currentUser = users.find((user) => user.userGuid === currentUserId);

    useEffect(() => {
        const { from } = location.state !== null && (location.state as LocationState);

        currentUser && setBackButtonText({ text: currentUser.fullName, link: from ? from.pathname : RouteUrls.AdminUsers });
        return () => {
            setBackButtonText(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    return (
        <Grid container direction={'column'}>
            <Grid item xs={12}>
                <AdminUserDetails user={currentUser} />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles<IAdminUserDetailsControllerProps>()((theme, props) => ({
    BackButton: {
        padding: 0,
        minHeight: 0,
        minWidth: 0,
    },
}));

export { AdminUserDetailsController };
