import React from 'react';
import { makeStyles } from '../../../../Themes/MakeStyles';
import { Box, Card, CardContent, Grid, Stack, styled, Switch, Typography } from '@mui/material';
import { Workstation } from '../../../../ServiceStack/ServiceStack.dtos';
import { TwoRowText } from '../../AdminCommon/TwoRowText';
import { getWorkstationStatus } from '../../AdminCommon/Helpers';
import { getCloudProviderIcon } from '../../../../Utility/Utility';
import { AdminWorkstationsActionsMenu } from '../AdminWorkstationsActionsMenu';
import { format } from 'date-fns';

export interface IWorkstationDetailsProps {
    workstation: Workstation;
}

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const AdminWorkstationDetailsOverview: React.FC<IWorkstationDetailsProps> = (props) => {
    const styles = useStyles(props);

    return (
        <Grid container direction={'row'}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container direction={'column'}>
                            <Grid container direction={'row'} justifyContent={'space-between'} item xs={12}>
                                <Grid item>
                                    <Typography variant={'h6'}>Workstation Overview</Typography>
                                </Grid>
                                <Grid item>
                                    <AdminWorkstationsActionsMenu
                                        disabled={false}
                                        hideRemoveWorkstation={true}
                                        showMapUsers={false}
                                        workstations={[props.workstation]}
                                    />
                                </Grid>
                            </Grid>
                            <Grid justifyContent={'space-between'} container item xs={12}>
                                <Grid item xs={4}>
                                    <TwoRowText topText={'Workstation Name'} bottomText={props.workstation.name} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TwoRowText topText={'LAN IP/FQDN'} bottomText={props.workstation.ipAddress} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TwoRowText
                                        topText={'Status'}
                                        bottomTextClassName={styles.classes.WorkstationStatusIndicator}
                                        bottomText={getWorkstationStatus(props.workstation.status, false)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container direction={'column'} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={'h6'}>Workstation Information</Typography>
                            </Grid>
                            <Grid justifyContent={'space-between'} container item xs={12}>
                                <Grid item xs={4}>
                                    <TwoRowText topText={'Workstation ID'} bottomText={props.workstation.machineId} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TwoRowText topText={'Created On'} bottomText={format(Date.parse(props.workstation.createdOn), 'MM/dd/yyyy')} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TwoRowText
                                        topText={'Last Modified On'}
                                        bottomText={format(Date.parse(props.workstation.lastModifiedOn), 'MM/dd/yyyy')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container direction={'column'} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={'h6'}>Cloud Information</Typography>
                            </Grid>
                            <Grid justifyContent={'space-between'} container item xs={12}>
                                <Grid item xs={4}>
                                    <TwoRowText
                                        topText={'Cloud Provider'}
                                        bottomText={
                                            <Box display='flex' justifyContent='start' alignItems='center'>
                                                {getCloudProviderIcon(props.workstation.cloudProvider, styles.classes.CloudProviderIcon)}
                                                {`\u00A0 ${props.workstation.cloudProvider.toUpperCase()}`}
                                            </Box>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TwoRowText topText={'AWS Instance ID'} bottomText={props.workstation.instanceId} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TwoRowText topText={'AWS Region'} bottomText={props.workstation.region} />
                                </Grid>
                            </Grid>
                            <Grid justifyContent={'space-between'} container item xs={12}>
                                <Grid item xs={12}>
                                    <TwoRowText
                                        topText={'Managed by CAS Manager'}
                                        bottomText={
                                            <Stack direction='row' spacing={1} alignItems='center'>
                                                <AntSwitch
                                                    checked={props.workstation.managedByCasManager}
                                                    inputProps={{ 'aria-label': 'ant design' }}
                                                />
                                                {props.workstation.managedByCasManager ? <Typography>On</Typography> : <Typography>Off</Typography>}
                                            </Stack>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles<IWorkstationDetailsProps>()((theme, props) => ({
    WorkstationStatusIndicator: {
        marginTop: '-.5rem',
        marginLeft: '.5rem',
    },
    CloudProviderIcon: {
        height: '1.5rem',
    },
}));

export { AdminWorkstationDetailsOverview };
