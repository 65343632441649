import React, { useState, Suspense, useEffect } from 'react';
import { makeStyles } from '../../../Themes/MakeStyles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { MapUsersDialog } from './AdminWorkstationsTable/MapUsersDialog';
import {
    AddExistingWorkstations,
    ListADComputers,
    RemoveWorkstations,
    StartMachines,
    StopMachines,
    Workstation,
} from '../../../ServiceStack/ServiceStack.dtos';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Authentication, ListWorkstationsId, ServiceStackClient, SnackBarOptions } from '../../../Recoil/Atoms';
import { cx } from 'tss-react/@emotion/css';
import { uuidv4 } from '../../../Utility/Utility';

export interface IAdminWorkstationsTableRowActionsMenuProps {
    workstations: Workstation[];
    showMapUsers: boolean;
    hideRemoveWorkstation?: boolean;
    disabled: boolean;
    onClearSelections?: () => void;
}

const AdminWorkstationsActionsMenu: React.FC<IAdminWorkstationsTableRowActionsMenuProps> = (props) => {
    const styles = useStyles(props);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showMapUsersDialog, setShowMapUsersDialog] = useState(false);
    const [showConfirmRemoveDialog, setShowConfirmRemoveDialog] = React.useState(false);
    const isRowActionsMenuOpen = Boolean(anchorEl);
    const client = useRecoilValue(ServiceStackClient);
    const [snackBarOptions, setSnackbarOptions] = useRecoilState(SnackBarOptions);
    const authorized = useRecoilValue(Authentication);
    const setListWorkstationId = useSetRecoilState(ListWorkstationsId(authorized ? authorized.userId : ''));

    const onClickRowActions = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onCloseRowActionsMenu = () => {
        setShowConfirmRemoveDialog(false);
        setAnchorEl(null);
    };

    //done because we have an action all that can be dirty
    const getMachineIdsToActionOn = (status: string[]): string[] => {
        if (status.indexOf('remove') > -1) {
            const workstationsToAction = props.workstations.map((el) => {
                return el.machineId;
            });
            return workstationsToAction;
        } else {
            const workstationsToAction = props.workstations.map((el) => {
                if (status.indexOf(el.status.toLowerCase()) > -1) {
                    return el.machineId;
                }
            });
            return workstationsToAction;
        }
    };

    const onClickStartWorkstations = () => {
        client
            .post(new StartMachines({ machineIds: getMachineIdsToActionOn(['stopped', 'stopping', 'unknown']) }))
            .then((response) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message:
                        props.workstations.length > 1
                            ? `${props.workstations.length} workstations are starting`
                            : props.workstations.length === 1 && `${props.workstations[0].name} is starting`,
                });
                //need to refresh our workstations
                props.onClearSelections && props.onClearSelections();
                setListWorkstationId(uuidv4());
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `There was an error starting workstations`,
                });
            });
        onCloseRowActionsMenu();
    };

    const onClickStopWorkstations = () => {
        client
            .post(new StopMachines({ machineIds: getMachineIdsToActionOn(['running', 'starting', 'unknown']) }))
            .then((response) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message:
                        props.workstations.length > 1
                            ? `${props.workstations.length} workstations are stopping`
                            : props.workstations.length === 1 && `${props.workstations[0].name} is stopping`,
                });
                //need to refresh our workstations
                props.onClearSelections && props.onClearSelections();
                setListWorkstationId(uuidv4());
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `There was an error stopping workstations`,
                });
            });
        onCloseRowActionsMenu();
    };

    const onClickConfirmRemove = () => {
        client
            .delete(new RemoveWorkstations({ machineIds: getMachineIdsToActionOn(['remove']) }))
            .then((response) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message:
                        props.workstations.length > 1
                            ? `${props.workstations.length} workstations have been removed`
                            : props.workstations.length === 1 && `${props.workstations[0].name} has been removed`,
                });
                //need to refresh our workstations
                props.onClearSelections && props.onClearSelections();
                setListWorkstationId(uuidv4());
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `There was an error stopping workstations`,
                });
            });
    };

    return (
        <>
            {props.showMapUsers && (
                <Suspense
                    fallback={
                        <MapUsersDialog
                            workstation={props.workstations[0]}
                            open={showMapUsersDialog}
                            onClose={setShowMapUsersDialog}
                            showTable={false}
                        />
                    }
                >
                    <MapUsersDialog workstation={props.workstations[0]} showTable={true} open={showMapUsersDialog} onClose={setShowMapUsersDialog} />
                </Suspense>
            )}
            <Dialog open={showConfirmRemoveDialog} onClose={() => setShowConfirmRemoveDialog(false)}>
                <DialogTitle>
                    {props.workstations.length > 1
                        ? `Are you sure you want to remove ${props.workstations.length} workstations?`
                        : props.workstations.length === 1 && `Are you sure you want to remove ${props.workstations[0].name}?`}
                </DialogTitle>
                <DialogContent className={cx(styles.classes.DialogContent)}>
                    <DialogContentText>This action cannot be undone</DialogContentText>
                </DialogContent>
                <DialogActions className={cx(styles.classes.DialogContent, styles.classes.Buttons)}>
                    <Button color={'inherit'} variant={'contained'} onClick={() => setShowConfirmRemoveDialog(false)}>
                        Cancel
                    </Button>
                    <Button variant={'contained'} color={'error'} onClick={onClickConfirmRemove} autoFocus>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
            <IconButton disabled={props.disabled} onClick={onClickRowActions}>
                <MoreVert className={cx(styles.classes.Icon)} />
            </IconButton>
            <Menu
                id='demo-positioned-menu'
                aria-labelledby='demo-positioned-button'
                anchorEl={anchorEl}
                open={isRowActionsMenuOpen}
                onClose={onCloseRowActionsMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem disabled={props.workstations.length === 1 && props.workstations[0].status === 'running'} onClick={onClickStartWorkstations}>
                    Start
                </MenuItem>
                <MenuItem disabled={props.workstations.length === 1 && props.workstations[0].status === 'stopped'} onClick={onClickStopWorkstations}>
                    Stop
                </MenuItem>
                {props.showMapUsers && <MenuItem onClick={() => setShowMapUsersDialog(true)}>User Mapping</MenuItem>}
                {!props.hideRemoveWorkstation && <MenuItem onClick={() => setShowConfirmRemoveDialog(true)}>Remove</MenuItem>}
            </Menu>
        </>
    );
};

const useStyles = makeStyles<IAdminWorkstationsTableRowActionsMenuProps>()((theme, props) => ({
    Icon: {
        fill: props.disabled ? 'lightgrey' : 'black',
    },
    DialogContent: {
        marginBottom: '.5rem',
    },
    Buttons: {
        marginRight: '1.25rem',
    },
}));

export { AdminWorkstationsActionsMenu };
