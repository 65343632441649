import React, { useState } from 'react';
import { makeStyles } from '../../../../../Themes/MakeStyles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { AddUserEntitlements, RemoveEntitlements, User, Workstation } from '../../../../../ServiceStack/ServiceStack.dtos';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Authentication, ListUsersId, ListWorkstationsId, ServiceStackClient, SnackBarOptions } from '../../../../../Recoil/Atoms';
import { cx } from 'tss-react/@emotion/css';
import { uuidv4 } from '../../../../../Utility/Utility';

export interface IAdminWorkstationDetailsUserManagementActionMenuProps {
    user: User;
    workstation: Workstation;
}

const AdminWorkstationDetailsUserManagementActionMenu: React.FC<IAdminWorkstationDetailsUserManagementActionMenuProps> = (props) => {
    const styles = useStyles(props);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showMapUsersDialog, setShowMapUsersDialog] = useState(false);
    const isRowActionsMenuOpen = Boolean(anchorEl);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [snackBarOptions, setSnackbarOptions] = useRecoilState(SnackBarOptions);
    const authorized = useRecoilValue(Authentication);
    const client = useRecoilValue(ServiceStackClient);
    const setListWorkstationId = useSetRecoilState(ListWorkstationsId(authorized ? authorized.userId : ''));
    const setListUsersId = useSetRecoilState(ListUsersId(authorized ? authorized.userId : ''));

    const onClickRowActions = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onCloseRowActionsMenu = () => {
        setAnchorEl(null);
    };

    const onCloseDialog = () => {
        setDialogOpen(false);
    };

    const onClickRemoveUser = () => {
        client
            .delete(
                new RemoveEntitlements({
                    entitlementIds: [props.user.workstations.find((w) => w.workstationId === props.workstation.machineId).entitlementId],
                })
            )
            .then((response) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message: `${props.user.fullName} has been removed from ${props.workstation.name}`,
                });
                setDialogOpen(false);
                setListWorkstationId(uuidv4());
                setListUsersId(uuidv4());
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `There was an error removing ${props.user.fullName} from ${props.workstation.name}`,
                });
            });
        setDialogOpen(false);
    };

    const onOpenDialog = () => {
        setDialogOpen(true);
        setAnchorEl(null);
    };

    return (
        <>
            <Dialog open={dialogOpen} onClose={onCloseDialog}>
                <DialogTitle>{`Are you sure you want to remove ${props.user.fullName} from ${props.workstation.name}?`}</DialogTitle>
                <DialogContent className={cx(styles.classes.DialogContent)}>
                    <DialogContentText>This action cannot be undone.</DialogContentText>
                </DialogContent>
                <DialogActions className={cx(styles.classes.DialogContent, styles.classes.Buttons)}>
                    <Button color={'inherit'} variant={'contained'} onClick={onCloseDialog} autoFocus>
                        Cancel
                    </Button>
                    <Button variant={'contained'} color={'error'} onClick={onClickRemoveUser}>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
            <IconButton onClick={onClickRowActions}>
                <MoreVert />
            </IconButton>
            <Menu
                id='demo-positioned-menu'
                aria-labelledby='demo-positioned-button'
                anchorEl={anchorEl}
                open={isRowActionsMenuOpen}
                onClose={onCloseRowActionsMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={onOpenDialog}>Remove</MenuItem>
            </Menu>
        </>
    );
};

const useStyles = makeStyles<IAdminWorkstationDetailsUserManagementActionMenuProps>()((theme, props) => ({
    DialogContent: {
        marginBottom: '.5rem',
    },
    Buttons: {
        marginRight: '1.25rem',
    },
}));

export { AdminWorkstationDetailsUserManagementActionMenu };
