import React, { useEffect, useState } from 'react';
import { makeStyles } from '../../../../Themes/MakeStyles';
import { Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Authentication, BackButtonText, ListUsers, ListWorkstations } from '../../../../Recoil/Atoms';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AdminWorkstationDetailsOverview } from './AdminWorkstationDetailsOverview';
import { AdminWorkstationDetailsUserManagement } from './AdminUserDetailsUserManagement/AdminWorkstationDetailsUserManagement';
import { RouteUrls } from '../../../../Routes/RouteUrls';
import { LocationState } from '../../../../Types/Types';

export interface IAdminWorkstationDetailsProps {}

const AdminWorkstationDetailsController: React.FC<IAdminWorkstationDetailsProps> = (props) => {
    const styles = useStyles(props);
    const authorized = useRecoilValue(Authentication);
    const workstations = useRecoilValue(ListWorkstations(authorized ? authorized.userId : ''));
    const users = useRecoilValue(ListUsers(authorized ? authorized.userId : ''));
    const location = useLocation();
    const currentMachineId = location.pathname.split('/')[3];
    const currentWorkstation = workstations.find((workstation) => workstation.machineId === currentMachineId);
    const [backButtonText, setBackButtonText] = useRecoilState(BackButtonText);

    const tab = () => {
        let path = location.pathname;
        if (path.includes('overview')) return 0;
        else if (path.includes('user-management')) return 1;
        else return 0;
    };

    const [currentTab, setCurrentTab] = useState(tab());

    useEffect(() => {
        const { from } = location.state !== null && (location.state as LocationState);

        currentWorkstation && setBackButtonText({ text: currentWorkstation.name, link: from ? from.pathname : RouteUrls.AdminWorkstations });
        return () => {
            setBackButtonText(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWorkstation]);

    return (
        <Grid container direction={'column'}>
            <Grid item xs={12}>
                <Tabs
                    onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                        setCurrentTab(newValue);
                    }}
                    value={currentTab}
                >
                    <Tab
                        label={<Typography>Overview</Typography>}
                        value={0}
                        component={Link}
                        to={`${RouteUrls.AdminWorkstations}/${currentWorkstation.machineId}/overview`}
                    />
                    <Tab
                        label={<Typography>User Management</Typography>}
                        wrapped={true}
                        value={1}
                        component={Link}
                        to={`${RouteUrls.AdminWorkstations}/${currentWorkstation.machineId}/user-management`}
                    />
                </Tabs>
            </Grid>
            <Grid item xs={12}>
                {currentTab === 0 && <AdminWorkstationDetailsOverview workstation={currentWorkstation} />}
                {currentTab === 1 && <AdminWorkstationDetailsUserManagement users={users} workstation={currentWorkstation} />}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles<IAdminWorkstationDetailsProps>()((theme, props) => ({
    BackButton: {
        padding: 0,
        minHeight: 0,
        minWidth: 0,
    },
}));

export { AdminWorkstationDetailsController };
