import { capitalizeFirstLetter } from '../../../Utility/Utility';
import React from 'react';
import { DotWithText } from '../../Common/DotWithText';

export const getWorkstationStatus = (status: string, isBlackText: boolean): JSX.Element => {
    return <DotWithText isBlackText={isBlackText} text={capitalizeFirstLetter(status)} color={getStatusColor(status)} />;
};

const getStatusColor = (status: string): string => {
    switch (status) {
        case 'stopped':
            return 'red';
        case 'running':
            return 'green';
    }
};
