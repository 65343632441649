import React, { Dispatch, SetStateAction, Suspense, useRef, useState } from 'react';
import { makeStyles } from '../../../../Themes/MakeStyles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, useTheme } from '@mui/material';
import { MapUsersTable } from './MapUsersTable';
import { MaterialTableLoadingSkeleton } from '../../../Common/MaterialTableLoadingSkeleton';
import { AddUserEntitlements, StartMachines, User, Workstation } from '../../../../ServiceStack/ServiceStack.dtos';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Authentication, ListUsersId, ListWorkstationsId, ServiceStackClient, SnackBarOptions } from '../../../../Recoil/Atoms';
import { cx } from 'tss-react/@emotion/css';
import { uuidv4 } from '../../../../Utility/Utility';

export interface IMapUserDialogProps {
    open: boolean;
    onClose: Dispatch<SetStateAction<boolean>>;
    showTable: boolean;
    workstation: Workstation;
}

const MapUsersDialog: React.FC<IMapUserDialogProps> = (props) => {
    const styles = useStyles(props);
    const theme = useTheme();
    const [snackBarOptions, setSnackbarOptions] = useRecoilState(SnackBarOptions);
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const client = useRecoilValue(ServiceStackClient);
    const tableRef = useRef();
    const authorized = useRecoilValue(Authentication);
    const setListWorkstationId = useSetRecoilState(ListWorkstationsId(authorized ? authorized.userId : ''));
    const setListUsersId = useSetRecoilState(ListUsersId(authorized ? authorized.userId : ''));

    const onClickSave = () => {
        client
            .post(new AddUserEntitlements({ machineId: props.workstation.machineId, userGuidList: selectedUsers.map((user) => user.userGuid) }))
            .then((response) => {
                onCloseDialog();
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message:
                        selectedUsers.length > 1
                            ? `${selectedUsers.length} users have been mapped to ${props.workstation.name}`
                            : selectedUsers.length === 1 && `${selectedUsers[0].fullName} has been mapped to ${props.workstation.name}`,
                });
                if (tableRef.current) {
                    (tableRef as any).current.onAllSelected(false);
                }
                setListWorkstationId(uuidv4());
                setListUsersId(uuidv4());
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `There was an error adding users`,
                });
            });
    };

    const onCloseDialog = () => {
        setSelectedUsers([]);
        props.onClose(false);
    };

    return (
        <Dialog maxWidth={'md'} fullWidth={true} open={props.open} onClose={onCloseDialog}>
            <DialogTitle>{`Map users to ${props.workstation.name}`}</DialogTitle>
            <DialogContent className={cx(styles.classes.DialogContent)}>
                {props.showTable ? (
                    <MapUsersTable workstation={props.workstation} onSelectUsers={setSelectedUsers} />
                ) : (
                    <MaterialTableLoadingSkeleton />
                )}
            </DialogContent>
            <DialogActions className={cx(styles.classes.DialogContent, styles.classes.Buttons)}>
                <Button color={'inherit'} variant={'contained'} onClick={onCloseDialog}>
                    Cancel
                </Button>
                <Button variant={'contained'} onClick={onClickSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles<IMapUserDialogProps>()((theme, props) => ({
    DialogContent: {
        marginBottom: '.5rem',
    },
    Buttons: {
        marginRight: '1.25rem',
    },
}));

export { MapUsersDialog };
