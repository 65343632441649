import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { makeStyles } from '../../Themes/MakeStyles';
import { Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteUrls } from '../../Routes/RouteUrls';
import { useRecoilState } from 'recoil';
import { SnackBarOptions } from '../../Recoil/Atoms';

export interface IErrorScreenProps extends FallbackProps {}

const ErrorScreen: React.FC<IErrorScreenProps> = (props) => {
    const styles = useStyles(props);
    const navigate = useNavigate();
    const [snackBarOptions, setSnackbarOptions] = useRecoilState(SnackBarOptions);
    const location = useLocation();

    //Intercept error from expired refresh token and redirect to login
    if ((props.error as any).type === 'RefreshTokenException') {
        setSnackbarOptions({
            snackBarProps: { open: true, autoHideDuration: 6000 },
            alertProps: { severity: 'info' },
            message: 'Your session has expired',
        });

        navigate(RouteUrls.Logout, { state: { from: { pathname: location.pathname } } });
    }

    return (
        <Grid container alignItems={'center'} justifyContent={'center'}>
            <Grid item>
                <Typography variant={'h6'} color={'red'}>
                    Something went wrong:
                </Typography>
                <Typography>{props.error.message}</Typography>
                {/*
                            <button onClick={resetErrorBoundary}>Reload</button>
*/}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles<IErrorScreenProps>()((theme, props) => ({}));

export { ErrorScreen };
