import { createTheme } from '@mui/material';
import { VegaQumuloPalette } from './VegaQumuloPalette';
import { VegaQumuloTypography } from './VegaQumuloTypography';
import { CommonThemeOverrides } from '../CommonThemeOverrides';

export const VegaQumuloTheme = createTheme({
    components: CommonThemeOverrides,
    palette: VegaQumuloPalette,
    typography: VegaQumuloTypography,
    spacing: 8,
});
