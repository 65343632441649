import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '../../../../Themes/MakeStyles';
import { Paper, useTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { useRecoilValue } from 'recoil';
import { Authentication, ListUsers } from '../../../../Recoil/Atoms';
import { User, Workstation, WorkstationUser } from '../../../../ServiceStack/ServiceStack.dtos';
import { tableIcons } from '../../../Common/TableIcons';
import { uuidv4 } from '../../../../Utility/Utility';
import { RouteUrls } from '../../../../Routes/RouteUrls';

export interface IMapUsersTableProps {
    workstation: Workstation;
    onSelectUsers: (users: User[]) => void;
}

const MapUsersTable: React.FC<IMapUsersTableProps> = (props) => {
    const styles = useStyles(props);
    const theme = useTheme();
    const authorized = useRecoilValue(Authentication);
    const users = useRecoilValue(ListUsers(authorized ? authorized.userId : ''));
    const tableRef = useRef();

    useEffect(() => {
        return function cleanup() {
            if (tableRef.current) {
                (tableRef as any).current.onAllSelected(false);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableRef]);

    const usersNotAlreadyOnWorkstation =
        users &&
        users.filter((user) => {
            if (props.workstation.users.find((u) => u.userGuid === user.userGuid)) {
            } else {
                return user;
            }
        });

    return (
        <MaterialTable
            key={uuidv4()}
            tableRef={tableRef}
            icons={tableIcons}
            components={{
                Container: (props) => <Paper {...props} elevation={0} />,
            }}
            columns={[
                {
                    title: 'Name',
                    field: 'fullName',
                },
                {
                    title: 'Username',
                    field: 'userName',
                },
                {
                    title: 'User Guid',
                    field: 'userGuid',
                },
                { title: 'Status', field: 'status' },
            ]}
            data={usersNotAlreadyOnWorkstation}
            localization={{
                toolbar: {
                    searchPlaceholder: 'Search...',
                },
            }}
            options={{
                showTitle: false,
                searchFieldAlignment: 'left',
                searchFieldVariant: 'outlined',
                searchFieldStyle: {
                    marginLeft: '-1.5rem',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    width: '150%',
                },
                headerStyle: {
                    backgroundColor: `${theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]}`,
                    color: theme.palette.grey[500],
                },
                selection: true,
                showTextRowsSelected: false,
            }}
            onSelectionChange={props.onSelectUsers}
        />
    );
};

const useStyles = makeStyles<IMapUsersTableProps>()((theme, props) => ({}));

export { MapUsersTable };
