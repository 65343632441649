import { Button, Grid, Typography } from '@mui/material';
import { SupportForm } from '../SupportForm/SupportForm';
import { useState } from 'react';
import { BRANDS, getBranding } from '../../Themes/Branding';
import { makeStyles } from '../../Themes/MakeStyles';

export interface IFooterProps {}

const Footer: React.FC<IFooterProps> = () => {
    const { classes, cx } = useStyles();
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);

    return (
        <>
            <SupportForm show={showFeedbackForm} onClose={() => setShowFeedbackForm(false)} />
            <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid item>
                    <Typography color={getBranding().AccentTextColor}>
                        {process.env.REACT_APP_BRANDING === BRANDS.Atelier
                            ? `atelier powered by Vega Cloud Platform | `
                            : `WorkRemote powered by ${getBranding().Brand} | `}
                        <Button className={cx(classes.ContactSupport)} variant={'text'} onClick={() => setShowFeedbackForm(true)}>
                            <Typography color={getBranding().AccentTextColor}>Contact Support</Typography>
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

const useStyles = makeStyles()((theme) => ({
    ContactSupport: {
        padding: 0,
    },
}));

export { Footer };
