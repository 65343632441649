import React, { useState, useEffect } from 'react';
import { FormControl, IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';


export interface PasswordInputProps {
    setPwd: (password: string) => void;
    validatePassword?: boolean;
    value?: string;
    error?: boolean;
    name: string;
    errorMessage?: string;
    placeholder?: string;
    id: string;
    fullWidth?: boolean;
    textFieldClassname?: string;
}

const PasswordInput: React.FC<PasswordInputProps> = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    const [passwordStr, setPasswordStr] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const onPasswordChange = (event) => {
        setPasswordStr(event.target.value);
        if (props.validatePassword) validatePassword(event.target.value);
    };

    useEffect(() => {
        props.setPwd(passwordStr);
    }, [passwordStr, props]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const validatePassword = (thePwd: string) => {
        let msg = '';
        let isValid = false;

        if (thePwd != null && thePwd.length > 0) {
            isValid = true;
        } else {
            msg = 'Password cannot be blank';
        }

        setErrorMsg(msg);
        setPasswordValid(isValid);
    };

    return (
        <FormControl>
            <TextField
                className={props.textFieldClassname}
                error={props.error ? props.error : passwordValid}
                label={!!props.placeholder ? props.placeholder : 'Password'}
                helperText={props.error ? props.errorMessage : errorMsg}
                type={showPassword ? 'text' : 'password'}
                name={props.name}
                autoComplete='password'
                variant='outlined'
                onChange={onPasswordChange}
                value={props.value ? props.value : passwordStr}
                fullWidth={props.fullWidth ? props.fullWidth : false}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton disableRipple aria-label='Toggle password visibility' onClick={handleClickShowPassword}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </FormControl>
    );
};

export default PasswordInput;
