import React from 'react';
import { Button, Paper, useTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { makeStyles } from '../../../../../Themes/MakeStyles';
import { User, Workstation } from '../../../../../ServiceStack/ServiceStack.dtos';
import { AdminWorkstationDetailsUserManagementActionMenu } from './AdminWorkstationDetailsUserManagementActionMenu';
import { tableIcons } from '../../../../Common/TableIcons';
import { cx } from 'tss-react/@emotion/css';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteUrls } from '../../../../../Routes/RouteUrls';
import { uuidv4 } from '../../../../../Utility/Utility';

export interface IAdminUserDetailsUserManagementTableProps {
    users: User[];
    workstation: Workstation;
}

const AdminWorkstationDetailsUserManagementTable: React.FC<IAdminUserDetailsUserManagementTableProps> = (props) => {
    const styles = useStyles(props);
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <MaterialTable
            key={uuidv4()}
            icons={tableIcons}
            components={{
                Container: (props) => <Paper {...props} elevation={0} />,
                Toolbar: (props) => <></>,
            }}
            columns={[
                {
                    title: 'Name',
                    field: 'fullName',
                    render: (rowData) => {
                        return (
                            <Button
                                onClick={() =>
                                    navigate(`${RouteUrls.AdminUsers}/${rowData.userGuid}`, {
                                        state: { from: { pathname: location.pathname } },
                                    })
                                }
                                className={cx(styles.classes.TableButtons)}
                                variant={'text'}
                            >
                                {rowData.fullName}
                            </Button>
                        );
                    },
                },
                {
                    title: 'Username',
                    field: 'userName',
                },
                {
                    title: 'Directory Status',
                    field: '',
                },
                {
                    title: 'Action',
                    field: 'status',
                    render: (data) => {
                        return <AdminWorkstationDetailsUserManagementActionMenu user={data} workstation={props.workstation} />;
                    },
                },
            ]}
            data={props.users}
            localization={{
                toolbar: {
                    searchPlaceholder: 'Search...',
                },
            }}
            options={{
                paging: false,
                showTitle: false,
                search: false,
                headerStyle: {
                    backgroundColor: `${theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]}`,
                    color: theme.palette.grey[500],
                },
                showTextRowsSelected: false,
            }}
        />
    );
};

const useStyles = makeStyles<IAdminUserDetailsUserManagementTableProps>()((theme, props) => ({
    TableButtons: {
        display: 'inline-block',
        padding: 0,
        minHeight: 0,
        minWidth: 0,
    },
}));

export { AdminWorkstationDetailsUserManagementTable };
